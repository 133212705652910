import { Module } from "vuex";

export const titleState: Module<iStore.Titles, iStore.RootState> = {
  namespaced: true,
  state: {
    title: "",
    modal: false,
    positionId: null,
    departmentId: null,
    additionalModal: null,
    opened: false,
    createClose: false
  },
  mutations: {
    TOGGLE_CREATE(state) {
      state.createClose = !state.createClose
    },
    SET_TITLE(state, payload) {
      state.title = payload;
    },
    SET_ADDITIONAL_MODAL(state, payload) {
      state.additionalModal = payload;
    },
    SET_POSITION_ID(state, payload) {
      state.positionId = payload
    },
    SET_DEPARTMENT_ID(state, payload) {
      state.departmentId = payload
    },
    CLOSE_ADDITIONAL_MODAL(state) {
      state.additionalModal = null
    },
    SET_MODAL(state, payload) {
      state.modal = payload
    },
    CLOSE_MODAL(state) {
      if (state.additionalModal) return state.additionalModal = null
      state.modal = false
    },
    CHANGE_OPENED_STATE(state) {
      state.opened = !state.opened
    }
  },
};
