<script lang="ts" setup>
import { Calendar } from "@/widgets";
import { ref } from "vue";

const expanded = ref<"start" | "end" | null>(null);

const [year, month, day] = new Date().toISOString().split("T")[0].split("-");

const date = ref({
  start: {
    day: day,
    month: month,
    year: year,
  },
  end: {
    day: day,
    month: month,
    year: year,
  },
});

const payload = ref({
  periodType: "currentMonth",
  period: [] as unknown as [string, string],
});

function setStartDate(data: { year: string; month: string; day: string }) {
  date.value.start = {
    year: data.year,
    month: data.month,
    day: data.day,
  };
  payload.value.period[0] = `${data.year}-${data.month}-${data.day}`;
}

function setEndDate(data: { year: string; month: string; day: string }) {
  date.value.start = {
    year: data.year,
    month: data.month,
    day: data.day,
  };
  payload.value.period[1] = `${data.year}-${data.month}-${data.day}`;
}

function expandCalendar(type: "start" | "end") {
  if (expanded.value === type) return (expanded.value = null);
  expanded.value = type;
}

function inputValue(event: Event, type: "start" | "end", _value: "year" | "month" | "day") {
  const { value } = (event as unknown as InputEvent).target as HTMLInputElement;
  const int = parseInt(value);
  if (/\D/g.test(value)) {
    date.value[type][_value] = "";
  } else {
    if (_value === "month") {
      if (int < 13) {
        date.value[type][_value] = int.toString().padStart(2, "0");
      } else {
        date.value[type][_value] = "12";
      }
    } else if (_value === "day") {
      const maxDateValue = new Date(parseInt(date.value[type].year), parseInt(date.value[type].month), 0).getDate();
      if (int < maxDateValue) {
        date.value[type][_value] = int.toString().padStart(2, "0");
      } else {
        date.value[type][_value] = maxDateValue.toString().padStart(2, "0");
      }
    } else if (_value === "year") {
      if (int < 2050) {
        date.value[type][_value] = int.toString();
      } else {
        date.value[type][_value] = "2050";
      }
    }
  }
}
function setPeriod(type: "today" | "yesterday" | "currentWeek" | "currentMonth" | "currentYear" | "allTime") {
  payload.value.periodType = type;
  switch (type) {
    case "allTime": {
      date.value = {
        start: {
          day: "01",
          month: "01",
          year: "2020",
        },
        end: {
          day: "01",
          month: "01",
          year: "2050",
        },
      };
      break;
    }
    case "currentMonth": {
      const maxDateValue = new Date(parseInt(year), parseInt(month), 0).getDate();
      date.value = {
        start: {
          day: "01",
          month: month,
          year: year,
        },
        end: {
          day: maxDateValue.toString().padStart(2, "0"),
          month: month,
          year: year,
        },
      };
      break;
    }
    case "currentWeek": {
      const today = Date.now() - (Date.now() % 86400000);
      const currentWeekDay = new Date().getDay();
      const count = currentWeekDay - 9;
      const dates = [];
      for (let i = count; i < 7 + count; i++) {
        dates.push(new Date(today + 86400000 * i));
      }
      date.value = {
        start: {
          day: dates[0].getDate().toString().padStart(2, "0"),
          month: dates[0].getMonth().toString().padStart(2, "0"),
          year: dates[0].getFullYear().toString().padStart(2, "0"),
        },
        end: {
          day: dates[dates.length - 1].getDate().toString().padStart(2, "0"),
          month: dates[dates.length - 1].getMonth().toString().padStart(2, "0"),
          year: dates[dates.length - 1].getFullYear().toString().padStart(2, "0"),
        },
      };
      break;
    }
    case "currentYear": {
      date.value = {
        start: {
          day: "01",
          month: "01",
          year: year,
        },
        end: {
          day: "31",
          month: "12",
          year: year,
        },
      };
      break;
    }
    case "today": {
      date.value = {
        start: {
          day: day,
          month: month,
          year: year,
        },
        end: {
          day: day,
          month: month,
          year: year,
        },
      };
      break;
    }
    case "yesterday": {
      const prevYear = parseInt(month) === 1;
      const prevMonth = parseInt(day) === 1;
      const maxDateValue = new Date(parseInt(year), prevYear ? 12 : prevMonth ? parseInt(month) - 1 : parseInt(month), 0).getDate();
      const result = {
        day: prevMonth ? maxDateValue.toString().padStart(2, "0") : (parseInt(day) - 1).toString().padStart(2, "0"),
        month: prevYear ? "12" : prevMonth ? (parseInt(month) - 1).toString().padStart(2, "0") : parseInt(month).toString().padStart(2, "0"),
        year: prevYear ? (parseInt(year) - 1).toString() : year,
      };
      date.value.start = result;
      date.value.end = result;
      break;
    }
    default: {
      break;
    }
  }
}
</script>

<template>
  <div class="analytics-filters">
    <div class="analytics-filters__search">
      <input type="text" placeholder="Поиск" />
    </div>
    <div class="analytics-filters__period">
      <p>Выберите период:</p>
      <div>
        <div class="analytics-filters__calendar">
          <p>Начало</p>
          <div class="analytics-filters__date">
            <div>
              <input type="text" @input="(e) => inputValue(e, 'start', 'day')" v-model="date.start.day" />
              <span>/</span>
              <input type="text" @input="(e) => inputValue(e, 'start', 'month')" v-model="date.start.month" />
              <span>/</span>
              <input type="text" @input="(e) => inputValue(e, 'start', 'year')" v-model="date.start.year" />
            </div>
            <button @click="expandCalendar('start')">
              <svg viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.63102 14.5482C9.63102 16.113 8.36249 17.3815 6.79769 17.3815C5.23288 17.3815 3.96436 16.113 3.96436 14.5482C3.96436 12.9834 5.23288 11.7148 6.79769 11.7148C8.3625 11.7148 9.63102 12.9834 9.63102 14.5482Z"
                  fill="#979DA0"
                  stroke="#979DA0"
                />
                <path
                  d="M1 9.2153H0V10.2153H1V9.2153ZM21 9.2153V10.2153H22V9.2153H21ZM7.33333 1.62109C7.33333 1.06881 6.88562 0.621094 6.33333 0.621094C5.78105 0.621094 5.33333 1.06881 5.33333 1.62109H7.33333ZM17 1.62109C17 1.06881 16.5523 0.621094 16 0.621094C15.4477 0.621094 15 1.06881 15 1.62109H17ZM4.33333 5.62109H17.6667V3.62109H4.33333V5.62109ZM20 7.95443V21.2878H22V7.95443H20ZM17.6667 23.6211H4.33333V25.6211H17.6667V23.6211ZM2 21.2878V7.95443H0V21.2878H2ZM4.33333 23.6211C3.04467 23.6211 2 22.5764 2 21.2878H0C0 23.681 1.9401 25.6211 4.33333 25.6211V23.6211ZM20 21.2878C20 22.5764 18.9553 23.6211 17.6667 23.6211V25.6211C20.0599 25.6211 22 23.681 22 21.2878H20ZM17.6667 5.62109C18.9553 5.62109 20 6.66576 20 7.95443H22C22 5.56119 20.0599 3.62109 17.6667 3.62109V5.62109ZM4.33333 3.62109C1.9401 3.62109 0 5.56119 0 7.95443H2C2 6.66576 3.04467 5.62109 4.33333 5.62109V3.62109ZM20 7.95443V8.67907H22V7.95443H20ZM21 7.67907H1V9.67907H21V7.67907ZM2 8.67907V7.95443H0V8.67907H2ZM1 10.2153H21V8.2153H1V10.2153ZM0 8.67907V9.2153H2V8.67907H0ZM22 9.2153V8.67907H20V9.2153H22ZM5.33333 1.62109V4.62109H7.33333V1.62109H5.33333ZM15 1.62109V4.62109H17V1.62109H15Z"
                  fill="#979DA0"
                />
              </svg>
            </button>
          </div>
          <Calendar v-if="expanded === 'start'" :type="'pick'" @picked="setStartDate" @close="expanded = null" />
        </div>
        <p>-</p>
        <div class="analytics-filters__calendar">
          <p>Конец</p>
          <div class="analytics-filters__date">
            <div>
              <input type="text" @input="(e) => inputValue(e, 'end', 'day')" v-model="date.end.day" />
              <span>/</span>
              <input type="text" @input="(e) => inputValue(e, 'end', 'month')" v-model="date.end.month" />
              <span>/</span>
              <input type="text" @input="(e) => inputValue(e, 'end', 'year')" v-model="date.end.year" />
            </div>
            <button @click="expandCalendar('end')">
              <svg viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.63102 14.5482C9.63102 16.113 8.36249 17.3815 6.79769 17.3815C5.23288 17.3815 3.96436 16.113 3.96436 14.5482C3.96436 12.9834 5.23288 11.7148 6.79769 11.7148C8.3625 11.7148 9.63102 12.9834 9.63102 14.5482Z"
                  fill="#979DA0"
                  stroke="#979DA0"
                />
                <path
                  d="M1 9.2153H0V10.2153H1V9.2153ZM21 9.2153V10.2153H22V9.2153H21ZM7.33333 1.62109C7.33333 1.06881 6.88562 0.621094 6.33333 0.621094C5.78105 0.621094 5.33333 1.06881 5.33333 1.62109H7.33333ZM17 1.62109C17 1.06881 16.5523 0.621094 16 0.621094C15.4477 0.621094 15 1.06881 15 1.62109H17ZM4.33333 5.62109H17.6667V3.62109H4.33333V5.62109ZM20 7.95443V21.2878H22V7.95443H20ZM17.6667 23.6211H4.33333V25.6211H17.6667V23.6211ZM2 21.2878V7.95443H0V21.2878H2ZM4.33333 23.6211C3.04467 23.6211 2 22.5764 2 21.2878H0C0 23.681 1.9401 25.6211 4.33333 25.6211V23.6211ZM20 21.2878C20 22.5764 18.9553 23.6211 17.6667 23.6211V25.6211C20.0599 25.6211 22 23.681 22 21.2878H20ZM17.6667 5.62109C18.9553 5.62109 20 6.66576 20 7.95443H22C22 5.56119 20.0599 3.62109 17.6667 3.62109V5.62109ZM4.33333 3.62109C1.9401 3.62109 0 5.56119 0 7.95443H2C2 6.66576 3.04467 5.62109 4.33333 5.62109V3.62109ZM20 7.95443V8.67907H22V7.95443H20ZM21 7.67907H1V9.67907H21V7.67907ZM2 8.67907V7.95443H0V8.67907H2ZM1 10.2153H21V8.2153H1V10.2153ZM0 8.67907V9.2153H2V8.67907H0ZM22 9.2153V8.67907H20V9.2153H22ZM5.33333 1.62109V4.62109H7.33333V1.62109H5.33333ZM15 1.62109V4.62109H17V1.62109H15Z"
                  fill="#979DA0"
                />
              </svg>
            </button>
          </div>
          <Calendar v-if="expanded === 'end'" :type="'pick'" @picked="setEndDate" @close="expanded = null" />
        </div>
      </div>
    </div>
    <div class="analytics-filters__button">
      <div>
        <button @click="setPeriod('today')" :class="{ active: payload.periodType === 'today' }">Сегодня</button>
        <button @click="setPeriod('yesterday')" :class="{ active: payload.periodType === 'yesterday' }">Вчера</button>
        <button @click="setPeriod('currentWeek')" :class="{ active: payload.periodType === 'currentWeek' }">Текущая неделя</button>
      </div>
      <div>
        <button @click="setPeriod('currentMonth')" :class="{ active: payload.periodType === 'currentMonth' }">Текущий месяц</button>
        <button @click="setPeriod('currentYear')" :class="{ active: payload.periodType === 'currentYear' }">Текущий год</button>
      </div>
      <div>
        <button @click="setPeriod('allTime')" :class="{ active: payload.periodType === 'allTime' }">За всё время</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.analytics-filters {
  width: 18vw;
  position: absolute;
  top: 0;
  right: 18em;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: var(--secondary-background-color);
  border-radius: 0.8em;
  & p {
    margin: 0;
  }
}

.resetInputs {
  display: block;
  outline: none;
  border: none;
  background-color: transparent;
  padding-block: 0;
  padding-inline: 0;
  padding: 0;
  text-align: center;
}

.analytics-filters__search {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & input {
    width: 100%;
    border-radius: 0.7em;
    padding: 0.5em 1em;
    background-image: url("@/share/assets/icons/search.svg");
    background-size: 5%;
    background-repeat: no-repeat;
    background-position: 95%;
    border: 0.1em solid var(--fourth-border-color);
  }
}

.analytics-filters__period {
  width: 100%;
  display: flex;
  flex-direction: column;
  & div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    & p:nth-child(2) {
      font-size: 1.5em;
    }
  }
}

.analytics-filters__calendar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.analytics-filters__date {
  padding: 0.3em 0.5em;
  display: flex;
  gap: 1em;
  border: 0.1em solid var(--fourth-border-color);
  border-radius: 0.4em;
  & input {
    @extend .resetInputs;
    width: 1.5em;
  }
  & input:nth-child(5) {
    width: 3em;
  }
  & span {
    display: block;
  }
  & button {
    @extend .resetInputs;
    cursor: pointer;
    & svg {
      width: 1em;
      display: block;
    }
  }
}

.analytics-filters__button {
  width: 100%;
  display: flex;
  gap: 1em;
  flex-direction: column;
  & div {
    display: flex;
    gap: 1em;
    & button {
      padding: 0.2em 0.5em;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      border: 0.1em solid var(--third-text-color);
      border-radius: 0.6em;
      color: var(--third-text-color);
      background-color: var(--background-color);
    }
  }
}
.active {
  background-color: var(--secondary-text-color) !important;
  color: var(--secondary-background-color) !important;
}
</style>
