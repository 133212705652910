<script lang="ts" setup>
import { useStore } from "@/entities";
import { sockets } from "@/entities";
import { computed, onMounted, ref } from "vue";
import {
  loader,
  MobileHeader,
  AssideNav,
  MainHeader,
  CreateTask,
  ResetPersonal,
  FirePerson,
  AddSubOrgs,
  Events,
  AddPerson,
  ResetPassword,
  CreateDepartment,
  AddPosition,
  UpdateWidget,
  HamburgerMenu,
  DeleteMessage,
  Connection,
  Request,
  AddMeetingEvent,
} from "@/widgets";
import { useRoute } from "vue-router";
import { socketEventHandler } from "./socketEventHandler";

const _container = ref<HTMLDivElement>();
const store = useStore();
const loaderState = computed(() => store.state.componentLoader.status);
const user = computed(() => store.state.user.info);
const route = useRoute();

if (document.cookie.length > 0) {
  const cookie = decodeURIComponent(document.cookie).split("=");
  if (cookie[0] === "sd-user") {
    const user = JSON.parse(cookie[1]);
    store.commit("personal/SET_ID", user.id);
    store.commit("user/SET_USER", user);
    store.commit("chat/SET_SELF_ID", { id: store.state.personal.id, orgId: store.state.user.info.organization.orgId, shortName: user.shortName });
  }
}

const modal = computed(() => store.state.titles.modal);
const additionalModal = computed(() => store.state.titles.additionalModal);

socketEventHandler(sockets, store, route);

function toggleDropdown() {
  store.commit("titles/CHANGE_OPENED_STATE");
  store.commit("titles/TOGGLE_CREATE");
  store.commit("titles/CHANGE_OPENED_STATE");
  store.commit("titles/TOGGLE_CREATE");
}

function checkClosedCreateModal(e: Event) {
  const list = [...(e.target as HTMLDivElement).classList.values()];
  const menu = list.every((c) => /dropdown-menu/.test(c));
  if (!menu && store.state.titles.opened) toggleDropdown();
}

onMounted(() => {
  if ("reset" in store.state.user.info) {
    store.commit("titles/SET_MODAL", "password");
  }
  sockets.connect();
  Promise.all([store.dispatch("personal/GET_ALL_PERSONAL"), store.dispatch("events/GET_NOTIFICATIONS", 1)])
    .then(() => {
      store.commit("componentLoader/TOGGLE_STATE");
    })
    .catch((error) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
  const isMobile = toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
  if (isMobile) {
    store.commit("app/CHANGE_STATE_MOBILE", true);
  } else {
    store.commit("app/CHANGE_STATE_MOBILE", false);
  }
  window.addEventListener("keyup", (e) => {
    if (e.code === "F2") store.commit("titles/SET_MODAL", "createTask");
    if (e.code === "F4" && (user.value.isAdmin || user.value.isManager || user.value.isHead)) store.commit("titles/SET_MODAL", "addPerson");
  });
});
</script>

<template>
  <loader v-if="loaderState"></loader>
  <Connection v-else-if="!$store.state.user.connected" />
  <div class="container" v-else ref="_container" @click="checkClosedCreateModal">
    <CreateTask v-if="modal === 'createTask'" />
    <AssideNav class="asside" v-if="!store.state.app.mobile" />
    <div class="container__view">
      <MainHeader v-if="!store.state.app.mobile" class="main-header" />
      <MobileHeader class="mobile-header" v-else />
      <main class="main">
        <router-view></router-view>
      </main>
    </div>
    <Events />
    <AddPerson v-if="modal === 'addPerson'" />
    <ResetPassword v-if="modal === 'password'" />
    <CreateDepartment v-if="modal === 'createDepartment' || additionalModal === 'createDepartment'" />
    <AddSubOrgs v-if="modal === 'addSubOrgs'" />
    <AddPosition v-if="modal === 'addPosition' || additionalModal === 'addPosition'" />
    <FirePerson v-if="modal === 'setFirePerson'" />
    <ResetPersonal v-if="modal === 'resetPersonal'" />
    <UpdateWidget v-if="modal === 'updateWidget'" />
    <DeleteMessage v-if="modal === 'delete-message'" />
    <Request v-if="modal === 'request'" />
    <AddMeetingEvent v-if="modal === 'newEvent'" />
    <HamburgerMenu />
  </div>
</template>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-grow: 1;
}

.container__view {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: calc(100% - 2em);
}
</style>
