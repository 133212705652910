<script setup lang="ts">
import { PropType, computed, ref } from "vue";
import { useStore } from "@/entities";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();

const store = useStore();
const emit = defineEmits(["action"]);

const title = computed(() => store.state.titles.title);

const topContext = ref(0);
const leftContext = ref(0);
const showContext = ref("close");

function contextOpen(name: string) {
  if (showContext.value === name) return (showContext.value = "close");
  showContext.value = name;
}
function contextClose() {
  showContext.value = "close";
}

const executorLinks = ref([
  {
    path: "/calendar",
    name: "Календарь",
    exact: true,
    icon: `calendarIcon.svg`,
    role: true,
  },
]);

function link() {
  if (title.value === `Обзор`) {
    return executorLinks.value[0];
  } else
    return {
      path: "",
      name: "",
      exact: "",
      icon: "",
      role: false,
    };
}
function showReturnButton() {
  if (route.path === "/") return false;
  if (store.state.app.returnMobile) {
    return true;
  } else return false;
}
function toggleMenu() {
  store.commit("app/CHANGE_STATE_MENU", true);
}
</script>

<template>
  <div class="mobile-header">
    <div class="return-but" v-if="showReturnButton()"><returnButton /></div>
    <div class="return-but" v-if="!showReturnButton()">
      <img @click="toggleMenu()" :src="require(`@/share/assets/icons/humburgerIcon.svg`)" alt="" />
    </div>
    <div class="title">
      <h4>{{ title }}</h4>
    </div>
    <div class="route-but">
      <router-link :to="link().path" v-if="link().role">
        <img :src="require(`@/share/assets/icons/${link().icon}`)" alt="" />
      </router-link>
      <img @click="contextOpen('task')" v-if="store.state.app.stateTask === 'task'" :src="require(`@/share/assets/icons/menuDotsIcon.svg`)" alt="" />
      <img @click="$router.push({ name: 'TaskCreate' })" v-if="store.state.titles.title === 'Задачи'" :src="require(`@/share/assets/icons/plusDarkIcon.svg`)" alt="" />
      <div v-if="store.state.titles.title === 'Моя организация'" class="settings" @close="contextClose">
        <img @click="contextOpen('settings')" :src="require(`@/share/assets/icons/contextMenu.svg`)" alt="" />
        <div class="settings__contexMenu" v-if="showContext === 'settings'">
          <div class="settings__contexMenu__wrp">
            <div class="settings__contexMenu__button" @click="$router.push({ name: 'OrgInfo' }), contextClose(), store.commit('app/CHANGE_STATE_RETURN', true)">
              <img :src="require(`@/share/assets/icons/infoGreyIcon.svg`)" />
              <button><span>Информация</span></button>
            </div>
            <div class="settings__contexMenu__button" @click="$router.push({ name: 'OrgDocx' }), contextClose(), store.commit('app/CHANGE_STATE_RETURN', true)">
              <img :src="require(`@/share/assets/icons/documentsIcon.svg`)" />
              <button><span>Документы</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <contextMenu class="contextMenu" :display="showContext === 'task'" :top="topContext" :left="leftContext" @close="contextClose">
    <div @click="store.commit('app/CHANGE_STATE_TASK', 'files'), contextClose(), store.commit('app/CHANGE_STATE_RETURN', true)">
      <img :src="require(`@/share/assets/icons/clip.svg`)" />
      <p>Вложения</p>
    </div>
    <div @click="store.commit('app/CHANGE_STATE_TASK', 'chat'), contextClose(), store.commit('app/CHANGE_STATE_RETURN', true)">
      <img :src="require(`@/share/assets/icons/token_chat.svg`)" />
      <p>Обсуждения</p>
    </div>
  </contextMenu>
</template>

<style lang="scss" scoped>
.contextMenu {
  & div {
    display: flex;
    align-items: center;
    gap: 0.3em;
    cursor: pointer;
    transition: transform 0.2s ease;
    &:hover {
      transform: scale(1.05);
    }
    &:active {
      transform: scale(0.95);
    }
    & p {
      margin: 0;
    }
    & img {
      aspect-ratio: 1/1;
      width: 1.2em;
    }
  }
}
h4 {
  margin: 0;
}
.mobile-header {
  position: relative;
  top: 0;
  background-color: var(--secondary-background-color);
  height: 3em;
  display: flex;
  gap: 1em;
  padding: 0 1em;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0.4em 0.6em -0.6em var(--secondary-text-color);
  z-index: 3;
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    & a {
      & img {
        display: block;
        aspect-ratio: 1/1;
        width: 1.3em;
      }
    }
  }
  // & div:last-child {
  //   justify-content: end !important;
  // }
  // & div:first-child {
  //   justify-content: start !important;
  // }
}
.return-but {
  width: 20%;
  & img {
    aspect-ratio: 1/1;
    width: 1.5em;
    cursor: pointer;
    transition: transform 0.2s ease;
    &:active {
      transform: scale(0.8);
    }
  }
}
.title {
  width: 70%;
}
.route-but {
  width: 20%;
  & img {
    aspect-ratio: 1/1;
    width: 1.5em;
    &:active {
      transform: scale(0.8);
    }
  }
}

.settings {
  position: relative;
}

.settings__contexMenu {
  width: 12em;
  position: absolute;
  bottom: -7em;
  right: -0.5em;
}

.settings__contexMenu__wrp {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 1em rgb(165, 165, 165);
  border-top-left-radius: 0.8em;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
}

.settings__contexMenu__button {
  display: flex;
  padding: 1em;
  justify-content: flex-start !important;
  & button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color: #032a2f;
    width: 7.5em;
    text-align: left;
  }
}

.settings__contexMenu__button:nth-child(1) {
  border-bottom: 0.1em solid #00000080;
}
</style>
