<script lang="ts" setup>
import { router, useStore } from "@/entities";
import { computed, onMounted, ref } from "vue";

const store = useStore();
const userInfo = computed(() => store.state.user.info);
const links = computed(() => store.getters["user/GET_ROLE"] || executorLinks.value);

let analyticsLink = "/analytics";

const { isDepHead, isHead, isVice } = userInfo.value;
if (isDepHead) {
  const id = store.state.personal.personalList.find((p) => p.id === userInfo.value.id)?.department.id;
  analyticsLink += "/department/" + id;
} else if (isVice || isHead) {
  analyticsLink += "/organization/" + store.state.personal.organization.id;
} else {
  analyticsLink += "/executor/" + userInfo.value.id;
}

const wrapped = ref(false);
const executorLinks = ref([
  {
    path: "/",
    name: "Обзор",
    icon: `reviewIcon.svg`,
    role: true,
  },
  {
    path: "/requests",
    name: "Задачи",
    icon: `halfBookIcon.svg`,
    role: true,
  },
  {
    path: "/chat",
    name: "Сообщения",
    icon: `messageWhite.svg`,
    role: true,
  },
  {
    path: "/members",
    name: "Сотрудники",
    icon: `oct.svg`,
    role: true,
  },
  {
    path: "/settings?state=departments",
    name: "Настройки",
    icon: `settings.svg`,
    role: userInfo.value.isAdmin || userInfo.value.isManager || userInfo.value.isHead,
  },
]);

function navigateToRoute(routeName: string, params?: any) {
  router.push({ name: routeName, params: params, query: { state: "info" } });
  store.commit("app/CHANGE_STATE_MENU", false);
}
</script>

<template>
  <nav class="navigation">
    <div class="top-nav">
      <div class="navigation__logo">
        <router-link :to="{ name: 'Home' }">
          <img :src="require(`@/share/assets/icons/logo.svg`)" alt="logo" />
          <p v-if="!wrapped">ServiceDesk</p>
        </router-link>
      </div>
      <ul class="navigation__links">
        <li v-for="(link, index) in links" :key="index">
          <router-link v-if="link.role" :to="link.path">
            <img :src="require(`@/share/assets/icons/${link.icon}`)" alt="" />
            <p v-if="!wrapped">{{ link.name }}</p>
          </router-link>
        </li>
      </ul>
    </div>
    <div>
      <div class="wrap" @click="wrapped = !wrapped">
        <img :src="require(`@/share/assets/icons/arrowRightWhite.svg`)" v-if="wrapped" />
        <img :src="require(`@/share/assets/icons/arrowLeftWhite.svg`)" v-if="!wrapped" />
        <p v-if="!wrapped">Свернуть</p>
      </div>
      <div class="messenger__receiver" @click="navigateToRoute('MembersCard', { index: userInfo.id })">
        <div class="messenger__receiver__avatar" v-if="userInfo">
          <img v-if="userInfo.avatar !== null" class="messenger__receiver__avatar__image" :src="userInfo.avatar" />
          <p v-else class="messenger__receiver__avatar__image darkgray">
            {{
              `${userInfo.person?.fullName.split(" ")[0][0]}${
                userInfo.person?.shortName.includes(".") ? userInfo.person?.shortName.split(".")[0].slice(-1) : userInfo.person?.shortName.split(" ")[1][0]
              }`
            }}
          </p>
        </div>
        <div class="member__name" v-if="!wrapped">
          <p :title="userInfo.person?.shortName">{{ userInfo.person?.shortName }}</p>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.top-nav {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.messenger__receiver {
  cursor: pointer;
  padding: 1em;
  display: flex;
  gap: 1em;
  align-items: center;

  & p {
    margin: 0;
    color: var(--text-color);
    font-size: 1.5em;
  }
}

.messenger__receiver__avatar {
  & p,
  img {
    display: flex;
    margin: 0;
    padding: 0;
    padding: 0.4em;
    font-size: 1.5em;
    width: 1.5em;
    aspect-ratio: 1/1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background-color: #707070;
    border-radius: 50%;
  }
}

.navigation {
  width: max-content;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--third-background-color);
}

.navigation__logo {
  padding: 0.6em;
  font-size: 2em;
  width: max-content;
  cursor: pointer;

  & a {
    display: flex;
    align-items: center;
    gap: 0.5em;
    text-decoration: none;

    & img {
      display: block;
      aspect-ratio: 1/1;
      width: 1.5em;
    }

    & p {
      margin: 0;
      color: var(--primary-color);
    }
  }
}

.navigation__links {
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;

  & li {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;

    & a {
      padding-left: 1em;
      padding-right: 1em;
      padding-top: 0.7em;
      padding-bottom: 0.7em;
      text-decoration: none;
      color: var(--text-color);
      font-size: 1.5em;
      display: flex;
      gap: 0.5em;
      border-radius: 0.4em;
      transition: transform 0.2s ease;

      &:active {
        transform: scale(0.95);
      }

      & p {
        padding-top: 0.15em;
        margin: 0;
      }

      & img {
        aspect-ratio: 1/1;
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  & a:hover {
    background-color: var(--button-hover-color);
    border-radius: 0.4em;
  }

  & .router-link-active {
    background-color: var(--primary-color);
  }

  & .router-link-active:hover {
    background-color: var(--primary-hover-color);
  }
}

.wrap {
  padding: 1em;
  text-decoration: none;
  color: var(--text-color);
  font-size: 1.5em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  border-radius: 0.4em;
  transition: transform 0.2s ease;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }

  & p {
    margin: 0;
  }

  img {
    aspect-ratio: 1/1;
    width: 1.5em;
  }
}

.member__name p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  max-width: 10em;
}
</style>
