<script lang="ts" setup>
import { ref } from "vue";
import LeftFields from "./components/LeftFields.vue";
import RightFields from "./components/RightFields.vue";

const payload = ref({});

function createNewMeetingEvent() {}
</script>

<template>
  <div class="meeting-modal">
    <div class="meeting-modal__wrp">
      <h2>Новое мероприятие</h2>
      <form @submit="createNewMeetingEvent" class="meeting-modal__form">
        <div class="meeting-modal__form__wrp">
          <LeftFields />
          <RightFields />
        </div>
        <div class="meeting-modal__form__control">
          <p><span>*</span> обязательное поле</p>
          <button type="submit" class="meeting-modal__form__submit">Создать</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.meeting-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--darkening-background);
  z-index: 6;
}

.meeting-modal__wrp {
  width: 60%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 2em;
  border-radius: 0.8em;
  font-size: 1.2em;
  gap: 1em;
  & h2 {
    margin: 0;
  }
}

.meeting-modal__form__wrp {
  display: flex;
  gap: 5em;
}

.meeting-modal__form__control {
  display: flex;
  justify-content: space-between;
}
</style>
