<script lang="ts" setup>
import { computed, PropType, ref } from "vue";
import CircleDiagram from "./CircleDiagram.vue";

const props = defineProps({
  accepted: {
    type: Number as PropType<number>,
    required: true,
  },
  onProgress: {
    type: Number as PropType<number>,
    required: true,
  },
  awaited: {
    type: Number as PropType<number>,
    required: true,
  },
  timeout: {
    type: Number as PropType<number>,
    required: true,
  },
  completedOnTime: {
    type: Number as PropType<number>,
    required: true,
  },
  count: {
    type: Number as PropType<number>,
    required: true,
  },
  averageTimeForNormal: {
    type: String as PropType<string>,
    required: false,
    default: () => "",
  },
  averageTimeForHeight: {
    type: String as PropType<string>,
    required: false,
    default: () => "",
  },
  normal: {
    type: Number as PropType<number>,
    required: false,
    default: () => 0,
  },
  height: {
    type: Number as PropType<number>,
    required: false,
    default: () => 0,
  },
});

const dataPersent = computed(() => {
  const data = {
    accepted: {
      value: props.accepted,
      color: "#70AE79",
      title: "Исполнено",
      persent: props.count > 0 ? Math.round((props.accepted / props.count) * 100 * 10) / 10 : 0,
    },
    onProgress: {
      value: props.onProgress,
      color: "#F79236",
      title: "В работе",
      persent: props.count > 0 ? Math.round((props.onProgress / props.count) * 100 * 10) / 10 : 0,
    },
    awaited: {
      value: props.awaited,
      color: "#8CBDE5",
      title: "В ожидании",
      persent: props.count > 0 ? Math.round((props.awaited / props.count) * 100 * 10) / 10 : 0,
    },
    timeout: {
      value: props.timeout,
      color: "#EB4242",
      title: "Просрочено",
      persent: props.count > 0 ? Math.round((props.timeout / props.count) * 100 * 10) / 10 : 0,
    },
  };
  // data.accepted.persent = props.count > 0 ? Math.round((data.accepted.value / props.count) * 100 * 10) / 10 : 0;
  // data.onProgress.persent = props.count > 0 ? Math.round((data.onProgress.value / props.count) * 100 * 10) / 10 : 0;
  // data.awaited.persent = props.count > 0 ? Math.round((data.awaited.value / props.count) * 100 * 10) / 10 : 0;
  // data.timeout.persent = props.count > 0 ? Math.round((data.timeout.value / props.count) * 100 * 10) / 10 : 0;
  return data;
});
</script>

<template>
  <div class="data-analytics">
    <div class="data-analytics__wrp">
      <div class="data-analytics__data">
        <div class="data-analytics__data__row bold">
          <p>Общее количество задач</p>
          <p>{{ count }}</p>
        </div>
        <div class="data-analytics__data__row">
          <p>Доля задач с высоким приоритетом:</p>
          <p>{{ height }}</p>
        </div>
        <div class="data-analytics__data__row">
          <p>Доля задач с обычным приоритетом:</p>
          <p>{{ normal }}</p>
        </div>
        <div class="data-analytics__data__row bold">
          <p>Количество задач в статусе:</p>
        </div>
        <div v-for="(data, index) of dataPersent" :key="index" class="data-analytics__data__row">
          <p :style="{ color: data.color }">"{{ data.title }}"</p>
          <p :style="{ color: data.color }">{{ data.value }}</p>
          <p :style="{ color: data.color }">&nbsp;{{ `(${data.persent}%)` }}</p>
        </div>
        <div class="data-analytics__data__row bold">
          <p>Процент выполнения задач в срок:</p>
          <p>{{ completedOnTime }}</p>
          <p>&nbsp;{{ `(${props.count > 0 ? Math.round((completedOnTime / props.count) * 100 * 10) / 10 : 0}%)` }}</p>
        </div>
        <div class="data-analytics__data__row bold">
          <p>Среднее время выполнения задач:</p>
        </div>
        <div class="data-analytics__data__row">
          <p>Задачи с высоким приоритетом</p>
          <p>{{ averageTimeForHeight }}</p>
        </div>
        <div class="data-analytics__data__row">
          <p>Задачи с обычным приоритетом</p>
          <p>{{ averageTimeForNormal }}</p>
        </div>
      </div>
      <CircleDiagram :data="dataPersent" :count="count" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.data-analytics {
  display: flex;
  flex-direction: column;
  & p {
    margin: 0.2em 0;
  }
}

.data-analytics__wrp {
  display: flex;
  align-items: flex-start;
  & div:nth-child(2) {
    flex-basis: 12%;
  }
}

.bold {
  margin: 0.5em 0 0.5em 0;
  font-weight: bold;
  font-size: 1.4em;
}

.data-analytics__data {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.data-analytics__data__row {
  width: 100%;
  display: flex;
  gap: 1em;
  font-size: 1.2em;
  & p:nth-child(1) {
    flex-basis: 60%;
  }
}
</style>
