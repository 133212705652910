import { createApp } from "vue";
import App from "./App.vue";
import { router, store, key } from "@/entities";
import { UI } from "./share/UI";

const app = createApp(App);

UI.forEach(el => {
  if (el.__name) {
    app.component(el.__name!, el)
  } else {
  }
})

app.use(router).use(store, key).mount("#app");
