import { getDaysMonth } from "@/views/sheduler/components/components/helpers";
import { Module } from "vuex";

const date = new Date()

export const shedulerState: Module<iStore.Sheduler, iStore.RootState> = {
  namespaced: true,
  state: {
    selected: "day",
    currentYear: date.getFullYear(),
    selectedYear: date.getFullYear(),
    currentMonth: date.getMonth(),
    selectedMonth: date.getMonth(),
    selectedDay: date.getDate(),
    weeks: getDaysMonth(date.getMonth(), date.getFullYear()),
  },
  mutations: {
    SET_SELECTED_TYPE(state, selected: string) {
      state.selected = selected
    },
    SET_DATE(state, payload: { month: number, day: number }) {
      state.selectedMonth = payload.month
      state.selectedDay = payload.day
      state.weeks = getDaysMonth(payload.month, state.selectedYear)
    },
    SET_MONTH(state, payload: number) {
      state.selectedMonth = payload
      state.weeks = getDaysMonth(payload, state.selectedYear)
    },
    SET_YEAR(state, payload: number) {
      state.selectedYear = payload
      state.weeks = getDaysMonth(state.selectedMonth, payload)
    },
  },
};
