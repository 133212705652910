<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { onBeforeRouteUpdate, useRouter } from "vue-router";
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";

interface TaskInfo {
  title: string;
  statusId: keyof typeof statusList;
}

interface TaskFields {
  id: number;
  inNumber: number;
  info: TaskInfo;
}

interface TaskRecord {
  id: number;
  taskId: number | null;
  subTaskId: number;
  subTask: TaskFields;
}

interface TreeResponse {
  root: TaskFields;
  records: TaskRecord[];
}

interface Node {
  id: number;
  x: number;
  y: number;
  number: number;
  title: string;
  status: string;
}

interface Link {
  from: { x: number; y: number };
  to: { x: number; y: number };
}

const store = useStore();
const router = useRouter();

const props = defineProps<{ task: iStore.Task }>();
const response = ref<TreeResponse | null>(null);
const nodes = ref<Node[]>([]);
const links = ref<Link[]>([]);
const tree_container = ref<HTMLDivElement | null>(null);
const hoveredNode = ref<Node | null>(null);
const mouseX = ref(0);
const mouseY = ref(0);
const svgWidth = ref(0);
const svgHeight = ref(0);
const drag = ref(false);
const draggingNode = ref({
  x: 0,
  y: 0,
});

const statusList = {
  1: "Создан",
  2: "В работе",
  3: "Согласование",
  4: "На подтверждении",
  5: "Приостановлен",
  6: "Возврат в работу",
  7: "Исполнен",
  8: "Не исполнен",
  9: "Отменен",
  10: "Отменен(на стадии согласования)",
};

function parseTree(tree: TreeResponse, width: number) {
  const positions = new Map<number, { x: number; y: number; title: string; status: string; number: number; id: number }>();
  const gapY = 70;
  const minGapX = 40;
  const maxGapX = 200;

  function calculateSubtreeWidth(nodeId: number): number {
    const children = tree.records.filter((r) => r.taskId === nodeId);

    if (!children.length) return minGapX;
    const width = Math.min(
      maxGapX,
      Math.max(
        minGapX,
        children.reduce((sum, child) => sum + calculateSubtreeWidth(child.subTaskId), 0)
      )
    );

    return width;
  }

  function getSubtreeBounds(nodeId: number): { left: number; right: number } {
    const children = tree.records.filter((r) => r.taskId === nodeId);
    if (!children.length) {
      const x = positions.get(nodeId)?.x || 0;
      return { left: x, right: x };
    }
    const childBounds = children.map((child) => getSubtreeBounds(child.subTaskId));
    return {
      left: Math.min(...childBounds.map((b) => b.left)),
      right: Math.max(...childBounds.map((b) => b.right)),
    };
  }

  function placeNode(nodeId: number, level: number, parentX: number | null = null) {
    const children = tree.records.filter((r) => r.taskId === nodeId);
    let x = parentX !== null ? parentX : width / 2;
    const task = tree.records.find((r) => r.subTaskId === nodeId)?.subTask || tree.root;

    if (children.length) {
      const subtreeWidth = calculateSubtreeWidth(nodeId);
      let childX = x - subtreeWidth / 2;

      children.forEach((child, i) => {
        placeNode(child.subTaskId, level + 1, childX);
        childX += calculateSubtreeWidth(child.subTaskId);
      });
      const { left, right } = getSubtreeBounds(nodeId);
      x = (left + right) / 2;
    }

    positions.set(nodeId, { x, y: (level + 0.5) * gapY, title: task.info.title, status: statusList[task.info.statusId], number: task.inNumber, id: task.id });
  }

  placeNode(tree.root.id, 0);

  nodes.value = Array.from(positions.entries()).map(([id, pos]) => {
    return {
      id,
      x: pos.x,
      y: pos.y,
      number: pos.number,
      title: pos.title,
      status: pos.status,
    };
  });

  links.value = tree.records
    .map((record) => ({
      from: positions.get(record.taskId!)!,
      to: positions.get(record.subTaskId)!,
    }))
    .filter((link) => link.from && link.to);

  svgWidth.value = width;
  svgHeight.value = Math.max(...nodes.value.map((n) => n.y)) + 100;
}

function handleMouseOver(event: MouseEvent, node: Node) {
  hoveredNode.value = node;
  mouseX.value = event.clientX + 10;
  mouseY.value = event.clientY + 10;
}

function handleMouseLeave() {
  hoveredNode.value = null;
}

function startDrag() {
  drag.value = true;
}
function move(e: MouseEvent) {
  if (drag.value) {
    draggingNode.value.x += e.movementX;
    draggingNode.value.y += e.movementY;
  }
}
function stopDrag() {
  drag.value = false;
}

onMounted(() => {
  if (tree_container.value) {
    const { width, top } = tree_container.value.getBoundingClientRect();
    tree_container.value.style.height = document.documentElement.clientHeight - top - 20 + "px";
    doRequest(`/tasks/get-tree?id=${props.task.treeId}`, { method: "GET" })
      .then(({ response: res }) => {
        response.value = res as TreeResponse;
        parseTree(res as TreeResponse, width);
      })
      .catch((error) => {
        store.commit("events/PUSH_EVENT", { id: undefined, message: error, type: "error" });
      });
  }
});

onBeforeRouteUpdate((to, from, next) => {
  if (tree_container.value) {
    doRequest(`/tasks/info?id=${to.params.id}`, { method: "GET" })
      .then(({ response }) => {
        store.commit("tasks/SET_TASK", response);
      })
      .catch((error) => {
        store.commit("events/PUSH_EVENT", {
          id: undefined,
          message: error,
          type: "error",
        });
        router.push({ name: "TaskList" });
      });
  }
  next();
});
</script>

<template>
  <div class="tree-container" ref="tree_container">
    <svg :width="svgWidth" :height="svgHeight" @mousemove="move" @mouseup="stopDrag">
      <line v-for="link in links" :key="link.from.x + '-' + link.to.x" :x1="link.from.x" :y1="link.from.y" :x2="link.to.x" :y2="link.to.y" stroke="#979DA0" stroke-width="2" />
      <circle
        v-for="node in nodes"
        class="circle"
        :key="node.id"
        :cx="node.x"
        :cy="node.y"
        r="20"
        :fill="$route.params.id === node.id.toString() ? '#f79236' : '#979DA0'"
        :stroke="$route.params.id === node.id.toString() ? '#f79236' : 'transparent'"
        @click="$router.push({ name: 'TaskInfo', params: { id: node.id }, query: { state: 'info' } })"
        stroke-width="2"
        @mouseover="(e) => handleMouseOver(e, node)"
        @mouseleave="handleMouseLeave"
        @mousedown="startDrag"
      />
      <text v-for="node in nodes" :key="'text-' + node.id" :x="node.x" :y="node.y + 5" text-anchor="middle" fill="#fff" font-size="14px" style="pointer-events: none">
        {{ node.number }}
      </text>
    </svg>
    <div v-if="hoveredNode" class="tooltip" :style="{ top: mouseY + 'px', left: mouseX + 'px' }">
      <p><strong>№:</strong> {{ hoveredNode.number.toString().padStart(6, "0") }}</p>
      <p><strong>Тема:</strong> {{ hoveredNode.title }}</p>
      <p><strong>Статус:</strong> {{ hoveredNode.status }}</p>
    </div>
  </div>
</template>

<style scoped>
.tree-container {
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tooltip {
  position: absolute;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ddd;
}
.circle:hover {
  stroke: orange;
  stroke-width: 3;
}
</style>
