<script lang="ts" setup></script>

<template>
  <div class="meeting-modal__fields-right">
    <div>
      <label for="">Участники</label>
      <select name="" id=""></select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.meeting-modal__fields-right {
  flex-basis: 30%;
}
</style>
