<script lang="ts" setup>
import { PropType, ref } from "vue";
import { doRequest } from "@/helpers";
import { useStore } from "@/entities";

const emit = defineEmits(["close", "sendMessage", "addFile", "remove"]);

const props = defineProps({
  destination: {
    type: String as PropType<string>,
    required: true,
  },
  showAddText: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  uploadedFiles: {
    type: Array as PropType<{ filename: string; mimetype: string; size: number; url: string; uid: string }[]>,
    required: true,
  },
});

const store = useStore();

const currentUploadedFilesList = ref<{ uid: string }[]>([]);

const _files = ref<{ filename: string; size: number; percent: number; uid: string }[]>(
  props.uploadedFiles.length ? props.uploadedFiles.map((f) => ({ filename: f.filename, size: f.size, percent: 100, uid: f.uid })) : []
);
const comment = ref("");
const showList = ref(false);

function sendMessage() {
  emit(props.showAddText ? "addFile" : "sendMessage", { files: _files.value.map((f) => ({ filename: f.filename, size: f.size, uid: f.uid })), comment: comment.value });
  comment.value = "";
  currentUploadedFilesList.value = [];
  showList.value = false;
}

function deleteFile(uid: string) {
  const index = _files.value.findIndex((f) => f.uid === uid);
  const currentIndex = currentUploadedFilesList.value.findIndex((f) => f.uid === uid);
  if (index !== -1) {
    doRequest(`/upload/delete/${uid}`, { method: "GET" })
      .then((response) => {
        _files.value.splice(index, 1);
        if (currentIndex !== -1) currentUploadedFilesList.value.splice(currentIndex, 1);
        emit("remove", uid);
      })
      .catch((error) => {
        store.commit("events/PUSH_EVENT", {
          id: undefined,
          message: error,
          type: "error",
        });
      });
  }
}

function humanFileSize(bytes: number, si: boolean = false, dp: number = 1) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + " " + units[u];
}

function progressHandler(event: ProgressEvent<XMLHttpRequestEventTarget>, file: string) {
  const percent = (event.loaded / event.total) * 100;
  const index = _files.value.findIndex((f) => f.filename === file);
  if (index !== -1) _files.value[index].percent = percent;
}

function errorHandler(event: ProgressEvent<XMLHttpRequestEventTarget>, file: string) {
  // _("status").innerHTML = "Upload Failed";
}

function abortHandler(event: ProgressEvent<XMLHttpRequestEventTarget>, file: string) {
  // _("status").innerHTML = "Upload Aborted";
}

function filesUpload(e: Event) {
  const input = e.target as HTMLInputElement;
  const { files } = input;
  if (files?.length) {
    [...files].forEach((file) => {
      if (!showList.value) showList.value = true;
      const index = _files.value.findIndex((f) => f.filename === file.name && f.size === file.size);
      if (index !== -1) return;
      _files.value.push({ filename: file.name, size: file.size, percent: 0, uid: "" });
      const form = new FormData();
      const xhr = new XMLHttpRequest();
      form.append(file.name, file, file.name);
      xhr.upload.addEventListener("progress", (ev) => progressHandler(ev, file.name));
      xhr.addEventListener("error", (ev) => errorHandler(ev, file.name));
      xhr.addEventListener("abort", (ev) => abortHandler(ev, file.name));
      xhr.open("POST", `${process.env.VUE_APP_API_URL}/v1/upload`);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-Destination", props.destination);
      xhr.send(form);
      xhr.onreadystatechange = function (ev) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            try {
              const result = JSON.parse(xhr.responseText);
              const fIndex = _files.value.findIndex((f) => f.filename === result.response.filename);
              if (fIndex !== -1) _files.value[fIndex].uid = result.response.uid;
              currentUploadedFilesList.value.push({ uid: result.response.uid });
            } catch (error) {}
          }
        }
      };
    });
    setTimeout(() => (input.value = ""), 0);
  }
}

function closeAndRemove() {
  currentUploadedFilesList.value.forEach((f) => {
    emit("remove", f.uid);
    const index = _files.value.findIndex((f) => f.uid === f.uid);
    if (index !== -1) _files.value.splice(index, 1);
  });
  currentUploadedFilesList.value = [];
  showList.value = false;
}
</script>

<template>
  <div>
    <div :class="{ hidden: !showAddText, 'file-uploader': showAddText }">
      <label for="_files1">{{ showAddText ? "Добавить файлы" : "" }}</label>
      <input
        type="file"
        id="_files1"
        @change="filesUpload"
        multiple
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*,.pdf"
      />
      <input type="button" v-if="(_files.length || uploadedFiles.length) && showAddText" @click="showList = true" value="Посмотреть файлы" />
    </div>
    <div class="actions" tabindex="0" v-if="showList">
      <div class="listFiles">
        <div class="listFiles__wrp">
          <h3>Выбрано {{ _files.length }} файлов</h3>
          <div class="files__wrp">
            <div v-for="(file, index) in _files">
              <div>
                <img :src="require(`@/share/assets/icons/fileIcon.svg`)" />
                <p>
                  {{ file.filename }}
                  <span class="fileSize">{{ humanFileSize(file.size) }}</span>
                </p>
              </div>
              <progress v-if="file.percent < 100" :value="file.percent" max="100"></progress>
              <span v-else></span>
              <button type="button" @click="deleteFile(file.uid)" class="close"></button>
            </div>
          </div>
          <p class="fileSize" v-if="!showAddText">Комментарий</p>
          <textAreaComp v-if="!showAddText" :textVal="comment" @inputVal="(value: string) => (comment = value)"></textAreaComp>
          <div class="file-uploader">
            <label for="_files2">Добавить файлы</label>
            <input
              type="file"
              id="_files2"
              @change="filesUpload"
              multiple
              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*,.pdf"
            />
          </div>
          <div class="button-wrp">
            <button type="button" @click="closeAndRemove" class="cancel button-btn">Закрыть</button>
            <button type="button" @click="sendMessage" class="approved button-btn" :disabled="!_files.length" :class="{ disabled: !_files.length }">
              {{ showAddText ? "Прикрепить" : "Отправить" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 480px) {
  .listFiles__wrp {
    width: calc(100vw - 4em);
    padding: 1em;

    & div {
      & p {
        width: 15em;
      }
    }
  }

  .actions {
    position: fixed;
  }
}

@media screen and (min-width: 480px) {
  .actions {
    position: fixed;
  }
  .listFiles__wrp {
    width: 100%;
    padding: 1.3em;
  }

  .files__wrp {
    & div {
      & p {
        width: 20em;
      }
    }
  }
}

.actions {
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--dark-background);
  display: grid;
  place-content: center;
}

.fileSize {
  color: var(--third-text-color);
  margin: 0;
}

.button-wrp {
  & .button-btn {
    display: block;
    padding: 1em;
    width: 100%;
    border: none;
    outline: none;
    color: var(--text-color);
    border-radius: 0.4em;
    cursor: pointer !important;
    transition: transform 0.2s ease;

    &:active {
      transform: scale(0.95);
    }
  }
}

.files__wrp {
  width: 30em;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow-y: auto;
  overflow-x: hidden;

  & span:nth-child(2) {
    position: relative;
    width: 4em;

    &::after {
      position: absolute;
      content: "";
      width: 1.3em;
      aspect-ratio: 1/1;
      top: 50%;
      right: -0.5em;
      transform: translate(0, -50%);
      background-image: url("@/share/assets/icons/checkedPrimary.svg");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  & progress {
    height: 0.6em;
    background-color: var(--primary-color);
    border-radius: 0.6em;
    display: block;

    &::-moz-progress-bar {
      border-radius: 0.6em;
    }

    &::-webkit-progress-bar {
      border-radius: 0.6em;
    }

    &::-webkit-progress-value {
      background: var(--primary-color);
      border-radius: 0.6em;
    }
  }

  &::-webkit-scrollbar {
    width: 0.2em;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--background-color);
  }

  & div {
    width: 100%;
    display: flex;
    gap: 0.5em;
    position: relative;
    justify-content: flex-start;
    align-items: center;

    & img {
      width: 3em;
    }

    & p {
      max-width: 15em;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
    }
  }
}

.listFiles {
  position: relative;

  &__wrp {
    border-radius: 0.4em;
    background-color: var(--secondary-background-color);
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    height: max-content;

    & div {
      padding: 0.3em;
      display: flex;
      gap: 0.5em;
    }
  }
}

.close {
  display: block;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1em;
  margin: 0 0 0 2em;
  aspect-ratio: 1/1;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.approved {
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }
}

.cancel {
  background-color: var(--secondary-text-color);

  &:hover {
    background-color: var(--button-deactivate-color) !important;
  }
}
.file-uploader {
  display: flex;
  align-items: center;
  gap: 1em;

  & p {
    margin: 0;
  }

  & label,
  & input[type="button"] {
    display: block;
    width: max-content;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0.6em 1em;
    background-color: var(--primary-color);
    border-radius: 0.4em;
    color: var(--text-color);
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      background-color: var(--primary-hover-color) !important;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  & input[type="file"] {
    display: none;
  }
}

.disabled {
  background-color: var(--button-deactivate-color) !important;
  &:hover {
    background-color: var(--button-deactivate-color) !important;
  }
}

.hidden {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  & label {
    display: block;
    width: 1.5em;
    aspect-ratio: 1/1;
    background-image: url("@/share/assets/icons/filesIcon.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  & input[type="file"] {
    display: none;
  }
}
</style>
