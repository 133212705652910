const actionMap = new Map([
  [
    1,
    {
      executor: [
        { action: "setConfirm", value: "Принять в работу" }
      ],
      creator: [
        { action: "setExecutor", value: "Заменить исполнителя" },
        { action: "createTask", value: "Создать задачу(подзадачу)" },
        { action: "freeze", value: "Приостановить задачу" },
        { action: "cancel", value: "Отменить" },
      ],
      other: [
        { action: "setConfirm", value: "Принять в работу" }
      ]
    }
  ],
  [
    2,
    {
      executor: [
        { action: "setExecuted", value: "Выполнено" },
        { action: "requestToFreeze", value: "Запросить приостановить задачу" },
        { action: "createTask", value: "Создать задачу(подзадачу)" },
      ],
      creator: [
        { action: "setExecutor", value: "Заменить исполнителя" },
        { action: "createTask", value: "Создать задачу(подзадачу)" },
        { action: "freeze", value: "Приостановить задачу" },
        { action: "cancel", value: "Отменить" },
      ],
      other: [
        { action: "setExecuted", value: "Выполнено" },
        { action: "createTask", value: "Создать задачу(подзадачу)" },
      ]
    }
  ],
  [
    4,
    {
      executor: [],
      creator: [
        { action: "setApproved", value: "Принять работу" },
        { action: "setReturnInWork", value: "Вернуть на доработку" },
      ],
      other: [
        { action: "setExecuted", value: "Выполнено" },
        { action: "createTask", value: "Создать задачу(подзадачу)" },
      ]
    }
  ],
  [
    5,
    {
      executor: [
        { action: "setConfirm", value: "Принять в работу" }
      ],
      creator: [
        { action: "setExecutor", value: "Заменить исполнителя" },
        { action: "resume", value: "Возобновить" },
        { action: "createTask", value: "Создать задачу(подзадачу)" }
      ],
      other: [
        { action: "setConfirm", value: "Принять в работу" }
      ]
    }
  ],
  [
    6,
    {
      executor: [
        { action: "setConfirm", value: "Принять в работу" }
      ],
      creator: [
        { action: "setExecutor", value: "Заменить исполнителя" },
        { action: "cancel", value: "Отменить" },
      ],
      other: [
        { action: "setConfirm", value: "Принять в работу" }
      ]
    }
  ],
  [
    7,
    {
      executor: [
      ],
      creator: [
        { action: "setReturnInWork", value: "Вернуть на доработку" },
        { action: "cancel", value: "Отменить" },
      ],
      other: [
        { action: "setConfirm", value: "Принять в работу" }
      ]
    }
  ],
  [
    9,
    {
      executor: [
      ],
      creator: [
        { action: "setReturnInWork", value: "Вернуть на доработку" },
      ],
      other: [
      ]
    }
  ]
])


export function getCreatedActions(state: number, config: { other: boolean, isCreator: boolean, isExecutor: boolean, same: boolean }) {
  if (isNaN(state)) return []
  const action = actionMap.get(state)
  if (!action) return []
  if (config.same) return [...action["creator"], ...action["executor"], { action: "createDuplicate", value: "Создать дубликат" }]
  if (config.isCreator) return [...action['creator'], { action: "createDuplicate", value: "Создать дубликат" }]
  if (config.isExecutor) return [...action['executor'], { action: "createDuplicate", value: "Создать дубликат" }]
  if (config.other) return [...action['other'], { action: "createDuplicate", value: "Создать дубликат" }]
}

/*
if (
    !props.task.executor &&
    (isCreator || isControl || isHead || isDepHead || isVice)
  )
    result.push({ action: "setExecutor", value: "Назначить исполнителя" });

  if ((isCreator || isHead) && props.task.control === null)
    result.push({ action: "setControl", value: "Назначить контролирующего" });

  if (isCreator || isHead || (isControl && props.task.executor !== null))
    result.push({ action: "setExecutor", value: "Заменить исполнителя" });

  if ((isCreator || isHead) && props.task.control !== null)
    result.push({ action: "setControl", value: "Заменить контролирующего" });

  if (isExecutor && props.task.info.status.id === 1) {
    result.push({ action: "setConfirm", value: "Принять в работу" });
  }
  if (
    props.task.approval &&
    props.task.approval.list.findIndex(
      (a) => parseInt(Object.entries(a)[0][0]) === id
    ) !== -1
  )
    result.push({ action: "setAgreement", value: "Согласовать" });
  if (
    props.task.approval &&
    props.task.approval.list.findIndex(
      (a) => parseInt(Object.entries(a)[0][0]) === id
    ) !== -1
  )
    result.push({ action: "setAgreement", value: "Согласовать" });
  if (isExecutor && props.task.info.status.id === 2)
    result.push({ action: "setExecuted", value: "Выполнено" });
  if (
    (isVice || isHead || isDepHead || isCreator || isControl || isExecutor) &&
    (props.task.info.statusId === 1 || props.task.info.statusId === 2)
  )
  if (props.task.info.status.id === 5 && (isCreator || isControl || isHead)) {
    result.push({ action: "setApproved", value: "Принять" });
  }
  if (props.task.info.status.id === 4 && (isCreator || isControl || isHead)) {
    result.push({ action: "setReturnInWork", value: "Вернуть на доработку" });
  }
  if (props.task.info.status.id === 1) {
    result.push({ action: "createTask", value: "Создать дубликат" })
  }
  return result;
*/