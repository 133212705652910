<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { computed, onMounted, ref } from "vue";

const store = useStore();
const emit = defineEmits(["close"]);

const personal = ref<{ name: string; position: string | null; value: number | null | false }[]>([]);

const __wrp = ref();
const payload = ref({
  executorId: null as null | number,
  taskId: store.state.tasks.task?.id,
});

const expandSelect = ref<number | null>(null);

function expander(num: number) {
  if (expandSelect.value === num) return (expandSelect.value = null);
  return (expandSelect.value = num);
}

function close(e: Event, close?: boolean) {
  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "task__actions") store.commit("titles/CLOSE_MODAL");
}

function doAction() {
  if (!payload.value.executorId) {
    return store.commit("events/PUSH_EVENT", {
      id: undefined,
      message: "Выберите исполнителя",
      type: "error",
    });
  }
  store.commit("titles/CLOSE_MODAL");
  store.commit("componentLoader/TOGGLE_STATE");
  doRequest("/tasks/duplicate", {
    headers: { "Content-Type": "application/json; charset=utf-8" },
    method: "POST",
    body: JSON.stringify(payload.value),
  })
    .then(({ response }) => {
      store.commit("titles/CLOSE_MODAL");
      store.commit("componentLoader/TOGGLE_STATE");
    })
    .catch((error) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
}

onMounted(() => {
  __wrp.value?.focus();
  doRequest("/personal/list", { method: "GET" })
    .then(({ response }) => {
      personal.value = response;
    })
    .catch((error) => {});
});
</script>

<template>
  <div class="task__actions" @click="close" @keyup.esc="$emit('close', false)" ref="__wrp" tabindex="0">
    <div class="setDepartment">
      <div class="setDepartment__wrp">
        <h1>Создать дубликат задача?</h1>
        <div class="task__actions__select select" :class="{ expand: expandSelect === null || expandSelect !== 2, collapse: expandSelect === 2 }">
          <p>Выберите исполнителя:</p>
          <select required v-model="payload.executorId" @click="expander(2)">
            <option :value="null" selected disabled>Выберите исполнителя</option>
            <option
              v-for="(pers, index) in personal"
              :key="index"
              :value="pers.value"
              :disabled="!pers.value"
              :style="{
                textAlign: pers.value ? 'left' : 'center',
                fontWeight: pers.value === false ? 'bold' : 'normal',
              }"
            >
              {{ `${pers.name} ${pers.position ? pers.position : ""}` }}
            </option>
          </select>
        </div>
        <div>
          <button @click="$store.commit('titles/CLOSE_MODAL')" class="cancel">Отмена</button>
          <button @click="doAction()" class="approved">Подтвердить</button>
        </div>
      </div>
      <button type="button" class="close" @click="close($event, true)"></button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.task__actions {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-background);
  display: grid;
  place-content: center;
}

.setDepartment {
  position: relative;

  &__wrp {
    border-radius: 0.4em;
    padding: 2em 5em;
    background-color: var(--secondary-background-color);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: max-content;

    & div {
      display: flex;
      gap: 1em;

      & .approved {
        background-color: var(--primary-color);

        &:hover {
          background-color: var(--primary-hover-color) !important;
        }
      }

      & .cancel {
        background-color: var(--secondary-text-color);

        &:hover {
          background-color: var(--button-deactivate-color) !important;
        }
      }
    }

    & select {
      cursor: pointer;
      outline: none;
      border: none;
      border: 0.1em solid var(--fourth-border-color);
      padding: 0.4em 0.4em;
      border-radius: 0.4em;
      color: var(--secondary-text-color);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-size: 1em;
      background-position: calc(100% - 1em) center;
      background-repeat: no-repeat;
    }

    .selected {
      background-image: url("@/share/assets/icons/arrowStickUpIcon.svg") !important;
    }

    .unselected {
      background-image: url("@/share/assets/icons/arrowStickDownIcon.svg") !important;
    }

    & button {
      display: block;
      padding: 1em 0;
      width: 100%;
      border: none;
      outline: none;
      color: var(--text-color);
      border-radius: 0.4em;
      cursor: pointer !important;
      transition: transform 0.2s ease;

      &:active {
        transform: scale(0.95);
      }
    }
  }
}

.active-button {
  background: var(--primary-color) !important;
  cursor: pointer !important;
  transition: transform 0.2s ease;

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}

.close {
  display: block;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1.5em;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: 1em;
  top: 1em;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.select {
  display: flex;
  flex-direction: column;
  width: 100%;
  & p {
    margin: 0;
  }
}

.task__actions__select {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 1em;

  & select {
    border: none;
    outline: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.424em;
    border: 0.1em solid var(--fourth-border-color);
    border-radius: 0.4em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    & option {
      width: 100%;
      margin: 0;
      display: flex;
    }
  }
}
.expand {
  &::after {
    position: absolute;
    right: 0.6em;
    top: 2.8em;
    content: "";
    width: 1em;
    aspect-ratio: 1/1;
    background-image: url("@/share/assets/icons/selectArrow.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.collapse {
  &::after {
    position: absolute;
    right: 0.6em;
    top: 2.8em;
    content: "";
    width: 1em;
    aspect-ratio: 1/1;
    background-image: url("@/share/assets/icons/selectArrow.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
  }
}
</style>
