import { doRequest } from "@/helpers";
import { Module } from "vuex";

export const eventsState: Module<iStore.iEvents, iStore.RootState> = {
  namespaced: true,
  state: {
    messages: [],
    notifications: [],
    request: {
      action: null,
      createdAt: "",
      link: "",
      message: "",
      personalId: 0,
      readed: false,
      type: { name: '' },
      id: 0
    }
  },
  mutations: {
    PUSH_EVENT(state, payload: { link: string, message: string, personalId: number, typeId: number }) {
      state.messages.push({ message: payload.message, type: "info" });
      setTimeout(() => {
        state.messages.splice(-1);
      }, 6000);
    },
    SET_NOTIFICATIONS(state, payload) {
      state.notifications.unshift(payload)
      state.messages.push({ message: payload.message, type: "info" });
      setTimeout(() => {
        state.messages.splice(-1);
      }, 6000);
    },
    SET_ALL_NOTIFICATIONS(state, payload) {
      state.notifications = payload
    },
    SET_REQUEST(state, payload) {
      state.request = payload
    },
    SET_READED(state, payload) {
      state.notifications.forEach((not) => {
        if (not.id === payload) not.readed = true
      })
    }
  },
  actions: {
    GET_NOTIFICATIONS({ commit }, offset): Promise<void> {
      return new Promise((resolve) => {
        doRequest(`/notifications/get?${new URLSearchParams({ offset })}`, { method: "GET" })
          .then(({ response }) => {
            commit("SET_ALL_NOTIFICATIONS", response);
            resolve();
          })
          .catch((error) => {
            commit("PUSH_MESSAGE", {
              id: undefined,
              message: error,
              type: "error",
            });
          });
      });
    },
    SET_NOTIFICATIONS_READED_BY_ID({ commit }, id: number): Promise<void> {
      return new Promise((resolve) => {
        doRequest(`/notifications/readed`, { method: "POST", body: JSON.stringify({ id }) })
          .then(({ response }) => {
            commit("SET_NOTIFICATIONS_READED", response);
            resolve();
          })
          .catch((error) => {
            commit("PUSH_MESSAGE", {
              id: undefined,
              message: error,
              type: "error",
            });
          });
      });
    },
  },
};
