<script lang="ts" setup>
import { PropType, ref } from "vue";

const text = ref("");

defineProps({
  placeholder: {
    type: String as PropType<string>,
    required: false,
  },
  width: {
    type: String as PropType<string>,
    required: false,
    default: "15em",
  },
  grow: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
});
</script>

<template>
  <div class="search" :style="{ width: grow ? 'unset' : width === '15em' ? '15em' : width, flexGrow: grow ? 1 : 0 }">
    <input type="text" :placeholder="placeholder" v-model="text" @keyup.enter="$emit('searchValue', text)" />
    <input type="button" />
  </div>
</template>

<style lang="scss" scoped>
.search {
  display: flex;
  border: 0.1em solid var(--fourth-border-color);
  border-radius: 0.7em;
  padding: 0.5em 1em;
  & input {
    outline: none;
    display: block;
    border: none;
    background-color: transparent;
  }
  & input[type="text"] {
    width: 100%;
  }
  & input[type="button"] {
    width: 1.5em;
    aspect-ratio: 1/1;
    background-image: url("@/share/assets/icons/search.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
