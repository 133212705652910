export function capitalLetter(e: Event) {
  const event = e as InputEvent;
  const input = e.target as HTMLInputElement;

  const cursorPosition = input.selectionStart;

  if (event.inputType === 'insertText') {
    if (/^\s/.test(input.value)) {
      input.value = input.value.replace(/^\s/, '');
    }

    if (/^([а-яa-z])/.test(input.value)) {
      input.value = input.value.replace(/^([а-яa-z])/, (match) => match.toUpperCase());
    }
  }
  input.setSelectionRange(cursorPosition, cursorPosition);
}

export function capitalFirstLetter(e: Event) {
  const input = e.target as HTMLInputElement;

  const cursorPosition = input.selectionStart;

  input.value = input.value.charAt(0).toUpperCase() + input.value.slice(1);

  input.setSelectionRange(cursorPosition, cursorPosition);
}