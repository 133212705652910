<script setup lang="ts">
import { computed, PropType } from "vue";
import { IPropsData } from "../test";

const radius = 130;
const widthCircle = radius * (Math.PI * 2);
const angle = 3.6;

const props = defineProps({
  data: {
    type: Object as PropType<IPropsData>,
    required: true,
  },
  count: {
    type: Number as PropType<number>,
    required: true,
  },
});

const circles = computed(() => {
  const result: { color: string; angle: number; title: string; width: string }[] = [];
  for (let i = 0; i < Object.entries(props.data).length; i++) {
    const persent = (Object.entries(props.data)[i][1].value / props.count) * 100;
    let angleSum = 0;
    for (let a = i; a > 0; a--) {
      if (a > 0) {
        angleSum += (Object.entries(props.data)[a - 1][1].value / props.count) * 100;
      }
    }
    const a = (widthCircle / 100) * persent;
    result.push({ angle: angleSum * angle, title: Object.entries(props.data)[i][1].title, color: Object.entries(props.data)[i][1].color, width: `${a} ${widthCircle - a}` });
  }
  return result;
});
</script>

<template>
  <div class="circle-wrp" id="wrp">
    <svg width="100%" height="100%" viewBox="0 0 300 300" xmlns:xlink="http://www.w3.org/1999/xlink">
      <circle
        v-for="(c, i) in circles"
        :key="i"
        cx="150"
        cy="150"
        :r="radius"
        :stroke="c.color"
        fill="transparent"
        stroke-width="38"
        stroke-dashoffset="0"
        :style="{ transform: `rotate(${c.angle - 90}deg)` }"
        :stroke-dasharray="c.width"
      ></circle>
      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="8em" fill="#182330">{{ count }}</text>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.circle-wrp {
  height: calc(100%);
  width: calc(100%);

  & svg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & circle {
      transform-origin: center center;
      transform-box: fill-box;
    }
  }
}
</style>
