<script lang="ts" setup>
import { router, useStore } from "@/entities";
import { PropType, ref } from "vue";
import { getCreatedActions } from "./resolveActions";

const emit = defineEmits(["soex", "action"]);

const props = defineProps({
  task: {
    type: Object as PropType<iStore.Task>,
    required: true,
  },
});
const select = ref(false);

const store = useStore();

const selectedOption = ref(null);

const topInfoContext = ref(0);
const leftInfoContext = ref(0);
const showInfo = ref(false);
const showDepInfo = ref(false);

const contextInfo = ref<{ org: string; dep: string; post: string }>({
  org: "",
  dep: "",
  post: "",
});

function contextInfoDepOpen(e: MouseEvent | TouchEvent, org: string, dep: string) {
  leftInfoContext.value = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
  topInfoContext.value = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;

  contextInfo.value.org = org;
  contextInfo.value.dep = dep;
  showDepInfo.value = true;
}
function contextInfoOpen(e: MouseEvent | TouchEvent, org: string, dep: string, post: string) {
  topInfoContext.value = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;
  leftInfoContext.value = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;

  contextInfo.value.org = org;
  contextInfo.value.dep = dep;
  contextInfo.value.post = post;

  showInfo.value = true;
}
function contextInfoClose() {
  topInfoContext.value = 0;
  leftInfoContext.value = 0;
  showInfo.value = false;
  showDepInfo.value = false;
}

function changeStateButton() {
  if (store.state.app.mobile) {
    router.push({ name: "TaskCreate" });
  } else store.commit("titles/SET_MODAL", selectedOption.value);
}

function changeToggleUp() {
  select.value = true;
}

function changeToggleDown(e: Event) {
  select.value = false;
  (e.target as HTMLElement).blur();
}

function resolveAction() {
  const { isHead, isVice, isDepHead, id, organization } = store.state.user.info;
  const isExecutor = props.task.executor?.id === id;
  if (!isExecutor && props.task.executorOrg.id !== props.task.creatorOrg.id && props.task.executorOrg.id === organization.orgId)
    return [{ action: "createDuplicate", value: "Создать дубликат" }];
  const isCreator = props.task.creator?.id === id || isHead;
  return getCreatedActions(props.task.info.statusId, { isCreator, isExecutor, same: isCreator && isExecutor, other: isVice || isDepHead });
}
</script>

<template>
  <contextMenu class="contextInfo" :display="showDepInfo" :top="topInfoContext" :left="leftInfoContext" @close="contextInfoClose">
    <div>
      <p><img :src="require(`@/share/assets/icons/infoIcon.svg`)" /> Краткая информация</p>
      <p>Организация: {{ contextInfo.org }}</p>
      <p>Руководитель: {{ contextInfo.dep }}</p>
    </div>
  </contextMenu>
  <contextMenu class="contextInfo" :display="showInfo" :top="topInfoContext" :left="leftInfoContext" @close="contextInfoClose">
    <div>
      <p><img :src="require(`@/share/assets/icons/infoIcon.svg`)" /> Краткая информация</p>
      <p><span>Организация:</span> {{ contextInfo.org }}</p>
      <p><span>Отдел:</span> {{ contextInfo.dep }}</p>
      <p><span>Должность:</span> {{ contextInfo.post }}</p>
    </div>
  </contextMenu>
  <div class="info__block__wrp">
    <div class="info__block">
      <p>Тема:</p>
      <p>{{ task.info.title }}</p>
    </div>
    <div class="info__block" v-if="task.info.deadLine">
      <p>Дата исполнения:</p>
      <p>
        {{
          /[0]{2,}\:[0]{2,}\:[0]{2,}/.test(task.info.deadLine)
            ? new Date(task.info.deadLine).toLocaleString("ru", {
                dateStyle: "medium",
              })
            : new Date(task.info.deadLine)
                .toLocaleString("ru", {
                  dateStyle: "medium",
                  timeStyle: "medium",
                })
                .split(",")
                .join(" ")
        }}
      </p>
    </div>
    <!-- <div class="info__block">
            <p>Тип задачи:</p>
            <p>&nbsp;</p>
          </div> -->
    <div class="info__block">
      <p>Приоритет:</p>
      <p>{{ task.priority.name }}</p>
    </div>
    <div class="info__block">
      <p>Статус:</p>
      <p
        :class="{
          created: task.info.statusId === 1,
          inWorked: task.info.statusId === 2,
          inAproved: task.info.statusId === 4,
          freezed: task.info.statusId === 5,
          inReturnWorked: task.info.statusId === 6,
          aproved: task.info.statusId === 7,
          canceled: task.info.statusId === 9,
        }"
      >
      <span></span>
        {{ task.info.status.name }}
      </p>
    </div>
  </div>
  <div class="info__block__wrp">
    <div class="info__block">
      <p>Организация:</p>
      <p>{{ task.creatorOrg.fullName }}</p>
    </div>
    <div class="info__block">
      <p>Инициатор:</p>
      <p>
        {{ `${task.creator.lastName} ${task.creator.firstName} ${task.creator.surName}`
        }}<img
          class="info__icon"
          @click="contextInfoOpen($event, task.creatorOrg.shortName, task.creatorDepartment.name, task.creator.position.shortName)"
          :src="require(`@/share/assets/icons/infoGreyIcon.svg`)"
        />
      </p>
    </div>
  </div>

  <div class="info__block__wrp">
    <div class="info__block" v-if="task.executorDepartment && task.executorDepartment.name">
      <p>Ответственный отдел:</p>
      <p>
        {{ `${task.executorDepartment.name}` }}
        <img
          class="info__icon"
          @click="
            contextInfoDepOpen(
              $event,
              `${task.creatorOrg.shortName}`,
              `${task.executorDepartment.head.lastName} ${task.executorDepartment.head.firstName} ${task.executorDepartment.head.surName}`
            )
          "
          :src="require(`@/share/assets/icons/infoGreyIcon.svg`)"
        />
      </p>
    </div>
    <div class="info__block" v-else>
      <p>Ответственный отдел не назначен</p>
      <p>&nbsp;</p>
    </div>
    <div class="info__block" v-if="task.executor !== null">
      <p>Ответственный исполнитель:</p>
      <p>
        {{ `${task.executor.lastName} ${task.executor.firstName} ${task.executor.surName}`
        }}<img
          class="info__icon"
          @click="contextInfoOpen($event, task.executorOrg.shortName, task.executorDepartment.name, task.executor.position.shortName)"
          :src="require(`@/share/assets/icons/infoGreyIcon.svg`)"
        />
      </p>
    </div>
    <div class="info__block" v-else>
      <p>Ответственный не назначен</p>
      <p>&nbsp;</p>
    </div>
  </div>
  <div class="info__block__wrp">
    <div class="info__block">
      <p>Выбор действия</p>
      <select :class="{ selected: select, unselected: !select }" v-model="selectedOption" @change="changeToggleDown" @focus="changeToggleUp" @blur="changeToggleDown">
        <option value="null" disabled selected>Выберите действие</option>
        <option v-for="(item, index) in resolveAction()" :key="index" :value="item.action">
          {{ item.value }}
        </option>
      </select>
      <button type="button" class="info__block__button" @click="changeStateButton()">Выполнить</button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.contextMenu {
  & div {
    display: flex;
    align-items: center;
    gap: 0.3em;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }

    & p {
      margin: 0;
    }

    & img {
      aspect-ratio: 1/1;
      width: 1.2em;
    }
  }
}

.info__icon {
  cursor: pointer;
}

.contextInfo {
  & div {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    & p {
      margin: 0;
      display: flex;
      gap: 0.3em;
      align-items: center;

      & span {
        color: var(--third-text-color);
      }
    }

    & img {
      width: 1.3em;
    }
  }
}

.info__block {
  display: flex;
  flex-direction: column;
  gap: 0.4em;

  & p {
    cursor: default;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.5em;

    & img {
      width: 1em;
    }
  }

  select {
    cursor: pointer;
    outline: none;
    border: none;
    border: 0.1em solid var(--fourth-border-color);
    padding: 0.5em;
    border-radius: 0.4em;
    color: var(--secondary-text-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-size: 1em;
    background-position: calc(100% - 1em) center;
    background-repeat: no-repeat;

    & option {
      border-radius: 0 !important;
      border-bottom-left-radius: 0.4em;
      border-bottom-right-radius: 0.4em;
      box-shadow: none;
    }
  }

  .selected {
    background-image: url("@/share/assets/icons/arrowStickUpIcon.svg") !important;
  }

  .unselected {
    background-image: url("@/share/assets/icons/arrowStickDownIcon.svg") !important;
  }

  & p:nth-child(1) {
    color: var(--third-text-color);
  }

  &__wrp {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-bottom: 0.5em;

    &:not(:last-child) {
      border-bottom: 0.1em solid var(--fourth-border-color);
    }
  }
}

.info-button-wrp {
  flex-direction: row !important;
}

.info__block__button {
  margin-top: 1px;
  background: none;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  align-self: flex-start;
  background-color: var(--primary-color);
  border-radius: 0.4em;
  padding: 0.4em 0.6em 0.4em 0.6em;
  color: var(--secondary-background-color);
  cursor: pointer;
  transition: transform 0.2s ease;

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }
}

.created {
  & span {
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    background-color: #00bfff !important;
    border-radius: 50%;
  }
}

.inWorked {
  & span {
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    background-color: #f79236 !important;
    border-radius: 50%;
  }
}

.inAproved {
  & span {
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    background-color: #22cdbf !important;
    border-radius: 50%;
  }
}

.inReturnWorked {
  & span {
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    background-color: #f3ef1c !important;
    border-radius: 50%;
  }
}

.aproved {
  & span {
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    background-color: #32cd32 !important;
    border-radius: 50%;
  }
}
.freezed {
  & span {
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    background-color: #979da0 !important;
    border-radius: 50%;
  }
}

.canceled {
  & span {
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    background-color: #eb2020 !important;
    border-radius: 50%;
  }
}
</style>
