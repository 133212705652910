import { Module } from "vuex";

export const analyticsState: Module<iStore.Analytics, iStore.RootState> = {
  namespaced: true,
  state: {
    filter: false
  },
  mutations: {
    TOGGLE_STATE(state) {
      state.filter = !state.filter
    },
    CLOSE_MODAL(state) {
      state.filter = false
    }
  },
};
