<script lang="ts" setup>
import { ref } from "vue";
import { FileUploader } from "@/features";

const emits = defineEmits(["time", "checked", "date", "files"]);

const eventTypeList = ref([
  { id: 1, name: "Межведомственное" },
  { id: 2, name: "Внутриведомственное" },
  { id: 3, name: "Структурное подразделение" },
]);

const dateTime = ref({
  date: getDate(),
  statusId: "" as string | number,
  startHour: "00",
  endHour: "00",
  startMinutes: "00",
  endMinutes: "00",
  wholeDay: false,
  repeat: false,
});

function getDate() {
  return new Date().toISOString().split("T")[0];
}

function blockEAndDots(event: KeyboardEvent) {
  if (event.key === "e" || event.key === "E" || event.key === "." || event.key === ",") {
    event.preventDefault();
  }
}

function blockPaste(event: ClipboardEvent) {
  const paste = event.clipboardData?.getData("text");
  if (!paste) return;
  if (paste.includes("e") || paste.includes("E") || paste.includes(".") || paste.includes(",")) {
    event.preventDefault();
  }
}

function validateInput(field: keyof typeof dateTime.value, max: number) {
  if (field === "endHour" || field === "endMinutes" || field === "startHour" || field === "startMinutes") {
    let num = parseInt(dateTime.value[field]);
    if (num < 0) dateTime.value[field] = "0";
    if (num > 23) dateTime.value[field] = max.toString();
  } else return;
}

function checked(field: "wholeDay" | "repeat") {}
</script>

<template>
  <div class="meeting-modal__fields-left">
    <div class="m_text_field">
      <label for="m_title" class="m_requred">Название<sup>*</sup></label>
      <input type="text" required placeholder="Название мероприятия" />
    </div>
    <div class="m_text_field">
      <label class="m_requred">Статус мероприятия<sup>*</sup></label>
      <select id="m_select_left" required v-model="dateTime.statusId">
        <option value="" selected disabled>Выберите</option>
        <option v-for="o in eventTypeList" :value="o.id">{{ o.name }}</option>
      </select>
    </div>
    <div class="m_text_field">
      <label class="m_requred">Время проведения<sup>*</sup></label>
      <div class="m_datetime">
        <input type="date" required v-model="dateTime.date" :min="getDate()" />
        <input type="number" required v-model="dateTime.startHour" min="0" max="23" @keydown="blockEAndDots" @input="validateInput('startHour', 23)" @paste="blockPaste" />
        <input type="number" required v-model="dateTime.startMinutes" min="0" max="59" @keydown="blockEAndDots" @input="validateInput('startMinutes', 59)" @paste="blockPaste" />
        <span>-</span>
        <input type="number" required v-model="dateTime.endHour" min="0" max="23" @keydown="blockEAndDots" @input="validateInput('endHour', 23)" @paste="blockPaste" />
        <input type="number" required v-model="dateTime.endMinutes" min="0" max="59" @keydown="blockEAndDots" @input="validateInput('endMinutes', 59)" @paste="blockPaste" />
      </div>
    </div>
    <div class="m_checkbox_field">
      <div>
        <label for="m_wholeDay">Весь день</label>
        <input type="checkbox" id="m_wholeDay" v-model="dateTime.wholeDay" @change="checked('wholeDay')" />
      </div>
      <div>
        <label for="m_repeat">Повторять</label>
        <input type="checkbox" id="m_repeat" v-model="dateTime.repeat" @change="checked('repeat')" />
      </div>
    </div>
    <div class="m_textarea_field">
      <label for="m_desc">Повторять</label>
      <textarea id="m_desc"></textarea>
      <FileUploader :destination="'task'" :showAddText="true" @add-file="" />
    </div>
    <div>
      <label for="m_place">Место проведения</label>
      <input type="text" id="m_place" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.meeting-modal__fields-left {
  width: 100;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  & label {
    color: #182330 !important;
  }
}

.m_text_field {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.m_datetime {
  display: flex;
  gap: 0.2em;
}

.m_checkbox_field {
  display: flex;
  gap: 0.8em;
}

.m_textarea_field {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  & textarea {
    resize: none;
  }
}
</style>
