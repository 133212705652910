<script lang="ts" setup>
import { computed, onMounted, PropType, ref, watch } from "vue";

const props = defineProps({
  tasks: {
    type: Array as PropType<Task.TaskAnalytics[]>,
    required: true,
  },
  range: {
    type: Object as PropType<{ start: string; end: string } | undefined>,
    required: false,
    default: () => undefined,
  },
});

const canvasRef = ref<HTMLCanvasElement | null>(null);
let cachedChartImage: ImageData | null = null;

// Возвращает количество дней в текущем месяце
function getDaysCountOfCurrentMonth(): number {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

// Извлекает дату из строки ISO
function getDate(str: string): string {
  return str.split("T")[0];
}

// Собираем статистику по датам
function getCountOfDates(arr: Task.TaskAnalytics[]) {
  const minDaysCount = getDaysCountOfCurrentMonth();
  const result = {
    x: props.range ? (new Date(props.range.end).getTime() - new Date(getDate(props.range.start)).getTime()) / 86400000 : minDaysCount,
    y: 0,
    tasks: {} as { [key: string]: { complited: number; created: number; indexes: number[] } },
  };
  for (let i = 0; i < arr.length; i++) {
    const createdDate = getDate(arr[i].info.createdAt);
    if (!result.tasks[createdDate]) result.tasks[createdDate] = { created: 0, complited: 0, indexes: [] };
    result.tasks[createdDate].created++;
    if (!result.tasks[createdDate].indexes.includes(arr[i].id)) result.tasks[createdDate].indexes.push(arr[i].id);

    if (arr[i].info.finished) {
      const finishedDate = getDate(arr[i].info.finished!);
      if (!result.tasks[finishedDate]) result.tasks[finishedDate] = { created: 0, complited: 0, indexes: [] };
      result.tasks[finishedDate].complited++;
      if (!result.tasks[finishedDate].indexes.includes(arr[i].id)) result.tasks[finishedDate].indexes.push(arr[i].id);
    }
  }
  Object.values(result.tasks).forEach((val) => {
    if (result.y < val.complited) result.y = val.complited;
    if (result.y < val.created) result.y = val.created;
  });
  if (result.x < minDaysCount) result.x = minDaysCount;
  if (result.y < 7) result.y = 7;
  return result;
}

// Функция для выбора оптимального шага оси X (в днях, месяцах или годах)
function getOptimalXStep(totalDays: number): number {
  if (totalDays <= 60) {
    return 1; // подписываем каждый день
  } else if (totalDays <= 365) {
    return 7; // подписываем каждую неделю
  } else if (totalDays <= 3 * 365) {
    return 30; // подписываем раз в месяц
  } else if (totalDays <= 10 * 365) {
    return 90; // подписываем раз в квартал
  } else {
    return 365; // подписываем раз в год
  }
}

// Тип для хранения данных по столбику (для mouse events)
type BarData = {
  x: number;
  y: number;
  width: number;
  height: number;
  data: { date: string; created: number; complited: number };
  type: "created" | "complited";
};

let bars: BarData[] = []; // глобальный массив для координат столбиков
let hoveredBar: BarData | null = null;

// Вычисляем метки для осей
const steps = computed(() => {
  const { x, y, tasks } = getCountOfDates(props.tasks);
  const stepsY = 7;
  const stepsYSize = Math.ceil(y / stepsY);
  let xLabels: number[] = [];
  let labelType: "day" | "week" | "month" | "year" = "day";

  const optimalStep = getOptimalXStep(x);

  if (optimalStep === 1) {
    labelType = "day";
    xLabels = Array.from({ length: Math.floor(x) + 1 }, (_, i) => i);
  } else if (optimalStep === 7) {
    labelType = "week";
    xLabels = Array.from({ length: Math.floor(x / 7) + 1 }, (_, i) => i * 7);
  } else if (optimalStep === 30 || optimalStep === 90) {
    labelType = "month";
    if (props.range) {
      const start = new Date(getDate(props.range.start));
      const end = new Date(getDate(props.range.end));
      const monthsDiff = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
      xLabels = Array.from({ length: monthsDiff + 1 }, (_, i) => i);
    } else {
      const monthsApprox = Math.floor(x / 30);
      xLabels = Array.from({ length: monthsApprox + 1 }, (_, i) => i);
    }
  } else if (optimalStep === 365) {
    labelType = "year";
    if (props.range) {
      const start = new Date(getDate(props.range.start));
      const end = new Date(getDate(props.range.end));
      const yearsDiff = end.getFullYear() - start.getFullYear();
      xLabels = Array.from({ length: yearsDiff + 1 }, (_, i) => i);
    } else {
      const yearsApprox = Math.floor(x / 365);
      xLabels = Array.from({ length: yearsApprox + 1 }, (_, i) => i);
    }
  }

  return { tasks, yLabels: Array.from({ length: stepsY + 1 }, (_, i) => i * stepsYSize), xLabels, totalDays: x, labelType };
});

// Функция для отрисовки столбика с округлёнными верхними углами
function drawRoundedBar(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) {
  if (height) {
    ctx.beginPath();
    ctx.moveTo(x, y + height);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height);
    ctx.closePath();
    ctx.fill();
  }
}

// Функция отрисовки графика с учетом подписей и событий мыши
function drawChart(canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext("2d");
  if (!ctx) return;
  const rect = canvas.getBoundingClientRect();
  const remainingHeight = window.innerHeight - rect.bottom + rect.height / 1.5;
  const aspectRatio = 12 / 3;
  canvas.width = remainingHeight * aspectRatio;
  canvas.height = remainingHeight;
  const width = canvas.width;
  const height = canvas.height;
  const paddingLeft = 60,
    paddingRight = 20,
    paddingTop = 40,
    paddingBottom = 40;
  const chartWidth = width - paddingLeft - paddingRight;
  const chartHeight = height - paddingTop - paddingBottom;

  // Очищаем массив столбиков для событий
  bars = [];

  // Получаем вычисленные данные
  const { tasks, yLabels, xLabels, totalDays, labelType } = steps.value;

  // Определяем начальную дату оси X
  let startDate: Date;
  if (props.range) {
    startDate = new Date(getDate(props.range.start));
  } else {
    const taskDates = Object.keys(tasks).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    if (props.range) startDate = taskDates.length ? new Date(taskDates[0]) : new Date();
    else {
      const now = new Date();
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    }
  }

  // Очищаем и заливаем фон
  ctx.clearRect(0, 0, width, height);
  ctx.fillStyle = "#ede8ef";
  ctx.fillRect(0, 0, width, height);

  // Отрисовка горизонтальной сетки и подписей по оси Y
  ctx.strokeStyle = "rgba(156, 161, 165, 1)";
  ctx.font = "16px Golos-Regular";
  ctx.fillStyle = "rgba(156, 161, 165, 1)";
  ctx.textAlign = "right";
  ctx.textBaseline = "middle";
  ctx.beginPath();
  yLabels.forEach((label) => {
    const yPos = height - paddingBottom - label * (chartHeight / yLabels[yLabels.length - 1]);
    ctx.moveTo(paddingLeft, yPos);
    ctx.lineTo(width - paddingRight, yPos);
    ctx.fillText(String(label), paddingLeft - 5, yPos);
  });
  ctx.stroke();

  // Отрисовка вертикальной сетки и подписей по оси X
  ctx.strokeStyle = "#eee";
  ctx.beginPath();
  if (labelType === "day") {
    ctx.fillStyle = "rgba(247, 146, 54, 1)";
    ctx.textAlign = "center";
    ctx.textBaseline = "top";
    const months: { [key: string]: number } = {};
    xLabels.forEach((label) => {
      const labelDate = new Date(startDate.getTime() + label * 86400000);
      const dayNumber = labelDate.getDate();
      const dayOfWeek = labelDate.getDay();
      const daysInMonth = new Date(labelDate.getFullYear(), labelDate.getMonth() + 1, 0).getDate();
      const monthName = labelDate.toLocaleString("default", { month: "long" });
      const isLastDayOfMonth = dayNumber === daysInMonth;
      ctx.fillStyle = dayOfWeek === 0 || dayOfWeek === 6 ? "rgba(151, 157, 160, 1)" : "rgba(247, 146, 54, 1)";
      const xPos = paddingLeft + 12 + (label / totalDays) * chartWidth;
      if (!months[monthName]) months[monthName] = 0;
      if (isLastDayOfMonth) months[monthName] = months[monthName] + xPos;
      ctx.fillText(String(dayNumber), xPos, height - paddingBottom + 5);
    });
    let offset = 0;
    Object.entries(months).forEach((val) => {
      const pos = val[1] + offset;
      ctx.fillText(String(val[0]), pos / 2, height - paddingBottom + 20);
      offset = val[1];
    });
  } else if (labelType === "week") {
    if (props.range) {
      ctx.fillStyle = "rgba(247, 146, 54, 1)";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      const months: { [key: string]: number } = {};
      xLabels.forEach((label) => {
        const xPos = paddingLeft + 12 + (label / totalDays) * chartWidth;
        const labelDate = new Date(startDate.getTime() + label * 86400000);
        const dayNumber = labelDate.getDate();
        const dayOfWeek = labelDate.getDay();
        ctx.fillStyle = dayOfWeek === 0 || dayOfWeek === 6 ? "rgba(151, 157, 160, 1)" : "rgba(247, 146, 54, 1)";
        ctx.fillText(String(dayNumber), xPos, height - paddingBottom + 5);
      });
      let offset = 0;
      Object.entries(months).forEach((val) => {
        const pos = val[1] + offset;
        ctx.fillText(String(val[0]), pos / 2, height - paddingBottom + 20);
        offset = val[1];
      });
    }
  } else if (labelType === "month") {
    // Подписи в единицах месяцев
    if (props.range) {
      ctx.fillStyle = "rgba(247, 146, 54, 1)";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      xLabels.forEach((label) => {
        const monthDate = new Date(startDate.getFullYear(), startDate.getMonth() + label, 1);
        const monthName = monthDate.toLocaleString("default", { month: "long" });
        const diffDays = (monthDate.getTime() - startDate.getTime()) / 86400000;
        const xPos = paddingLeft + 40 + (diffDays / totalDays) * chartWidth;
        ctx.fillText(monthName.slice(0, 3), xPos, height - paddingBottom + 5);
      });
    }
  } else if (labelType === "year") {
    // Подписи в единицах лет
    if (props.range) {
      ctx.fillStyle = "rgba(247, 146, 54, 1)";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      xLabels.forEach((label) => {
        const yearDate = new Date(startDate.getFullYear() + label, startDate.getMonth(), startDate.getDate());
        const yearName = yearDate.getFullYear();
        const diffDays = (yearDate.getTime() - startDate.getTime()) / 86400000;
        const xPos = paddingLeft + 12 + (diffDays / totalDays) * chartWidth;
        ctx.fillText(String(yearName), xPos, height - paddingBottom + 5);
      });
    }
  }

  // Отрисовка столбиков по датам
  const barGroupWidth = 10;
  const barWidth = barGroupWidth / 2;
  bars = []; // очищаем массив для mouse events

  Object.keys(tasks)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .forEach((dateStr) => {
      const dayData = tasks[dateStr];
      const currentDate = new Date(dateStr);
      const diffDays = (currentDate.getTime() - startDate.getTime()) / 86400000;
      const xPos = paddingLeft + (diffDays / totalDays) * chartWidth;

      const scaleY = chartHeight / (yLabels[yLabels.length - 1] || 7);
      const createdBarHeight = dayData.created * scaleY;
      const complitedBarHeight = dayData.complited * scaleY;

      // Рисуем столбик для созданных задач (слева)
      const createdY = height - paddingBottom - createdBarHeight;
      ctx.fillStyle = "#8ec7ff";
      let barGroupWidth = 10;
      switch (labelType) {
        case "day":
          barGroupWidth = 10;
          break;
        case "week":
          barGroupWidth = 6;
          break;
        case "month":
          barGroupWidth = 4;
          break;
        case "year":
          barGroupWidth = 2;
          break;
      }
      const barWidth = barGroupWidth + 3;
      const createdX = xPos - barWidth;
      drawRoundedBar(ctx, createdX - 4, createdY, barWidth, createdBarHeight, 8);
      bars.push({
        x: createdX - 4,
        y: createdY,
        width: barWidth,
        height: createdBarHeight,
        data: { date: dateStr, created: dayData.created, complited: dayData.complited },
        type: "created",
      });

      // Рисуем столбик для выполненных задач (справа)
      const complitedX = xPos;
      const complitedY = height - paddingBottom - complitedBarHeight;
      ctx.fillStyle = "#8ad98a";
      drawRoundedBar(ctx, complitedX + 4, complitedY, barWidth, complitedBarHeight, 8);
      bars.push({
        x: complitedX + 4,
        y: complitedY,
        width: barWidth,
        height: complitedBarHeight,
        data: { date: dateStr, created: dayData.created, complited: dayData.complited },
        type: "complited",
      });
    });

  // Отрисовка подписи оси Y (вертикальная)
  ctx.save();
  ctx.translate(15, height / 2);
  ctx.rotate(-Math.PI / 2);
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillStyle = "#666";
  ctx.fillText("Количество задач", 0, 0);
  ctx.restore();
  cachedChartImage = ctx.getImageData(0, 0, width, height);
}

function handleMouseMove(event: MouseEvent) {
  const canvas = canvasRef.value;
  if (!canvas) return;
  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  const rect = canvas.getBoundingClientRect();
  const mouseX = event.clientX - rect.left;
  const mouseY = event.clientY - rect.top;

  let foundBar: BarData | null = null;
  for (const bar of bars) {
    if (mouseX >= bar.x && mouseX <= bar.x + bar.width && mouseY >= bar.y && mouseY <= bar.y + bar.height) {
      foundBar = bar;
      break;
    }
  }

  if (foundBar) {
    if (hoveredBar !== foundBar) {
      hoveredBar = foundBar;
      // Восстанавливаем сохранённое изображение графика
      if (cachedChartImage) {
        ctx.putImageData(cachedChartImage, 0, 0);
      }
      ctx.save();
      ctx.strokeStyle = "#000";
      ctx.lineWidth = 2;
      ctx.strokeRect(foundBar.x, foundBar.y, foundBar.width, foundBar.height);
      ctx.restore();
    }
  } else if (hoveredBar) {
    // Если курсор больше не над ни одним столбиком, убираем выделение
    hoveredBar = null;
    if (cachedChartImage) {
      ctx.putImageData(cachedChartImage, 0, 0);
    }
  }
}

function handleMouseLeave() {
  if (hoveredBar) {
    hoveredBar = null;
    const canvas = canvasRef.value;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx && cachedChartImage) {
        ctx.putImageData(cachedChartImage, 0, 0);
      }
    }
  }
}

onMounted(() => {
  if (canvasRef.value) {
    drawChart(canvasRef.value);
    canvasRef.value.addEventListener("mousemove", handleMouseMove);
    canvasRef.value.addEventListener("mouseleave", handleMouseLeave);
  }
});

watch(
  () => props.tasks,
  () => {
    if (canvasRef.value) {
      drawChart(canvasRef.value);
    }
  }
);
</script>

<template>
  <div class="bar">
    <canvas ref="canvasRef"></canvas>
  </div>
</template>

<style lang="scss" scoped>
.bar {
  display: flex;
  flex-grow: 1;
  width: 100%;
}
</style>
