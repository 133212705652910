<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { computed, onMounted, ref } from "vue";

const store = useStore();
const emit = defineEmits(["close"]);
const __wrp = ref();

const personal = ref<{ name: string; position: string | null; value: number | null | false }[]>([]);

const selected = ref<null | number>(null);

function close(e: Event, close?: boolean) {
  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "task__actions") store.commit("titles/CLOSE_MODAL");
}

function doAction() {
  if (!selected.value) return;
  const payload = {
    executorId: selected.value,
    taskId: store.state.tasks.task?.id,
  };
  store.commit("componentLoader/TOGGLE_STATE");
  doRequest("/tasks/replace/executor", {
    headers: { "Content-Type": "application/json; charset=utf-8" },
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then(({ response }) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("titles/CLOSE_MODAL");
    })
    .catch((error) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
}

onMounted(() => {
  __wrp.value?.focus();
  doRequest("/personal/list", { method: "GET" })
    .then(({ response }) => {
      personal.value = response;
    })
    .catch((error) => {});
});
</script>

<template>
  <div class="task__actions" @click="close($event, false)" @keyup.esc="close($event, false)" ref="__wrp" tabindex="0">
    <div class="setdepartment">
      <div class="setdepartment__wrp">
        <h2>Заменить исполнителя:</h2>
        <select v-model="selected">
          <option disabled selected :value="null">Выберите сотрудника</option>
          <option
            v-for="(pers, index) in personal"
            :key="index"
            :value="pers.value"
            :disabled="!pers.value"
            :style="{ textAlign: pers.value ? 'left' : 'center', fontWeight: pers.value === false ? 'bold' : 'normal' }"
          >
            {{ pers.name }}
          </option>
        </select>
        <button type="button" :class="{ 'active-button': selected }" :disabled="!selected" @click="doAction">Назначить</button>
      </div>
      <button type="button" class="close" @click="close($event, true)"></button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.task__actions {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-background);
  display: grid;
  place-content: center;
}

.setdepartment {
  position: relative;

  &__wrp {
    border-radius: 0.4em;
    padding: 2em 5em;
    background-color: var(--secondary-background-color);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: max-content;

    & select {
      cursor: pointer;
      outline: none;
      border: none;
      border: 0.1em solid var(--fourth-border-color);
      padding: 0.4em 0.4em;
      border-radius: 0.4em;
      color: var(--secondary-text-color);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-size: 1em;
      background-position: calc(100% - 1em) center;
      background-repeat: no-repeat;
    }

    .selected {
      background-image: url("@/share/assets/icons/arrowStickUpIcon.svg") !important;
    }

    .unselected {
      background-image: url("@/share/assets/icons/arrowStickDownIcon.svg") !important;
    }

    & button {
      display: block;
      padding: 1em 0;
      width: 100%;
      border: none;
      outline: none;
      background-color: var(--button-deactivate-color);
      color: var(--text-color);
      border-radius: 0.4em;
      cursor: not-allowed;

      &:hover {
        color: var(--text-hover-color);
      }
    }
  }
}

.active-button {
  background: var(--primary-color) !important;
  cursor: pointer !important;
  transition: transform 0.2s ease;

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}

.close {
  display: block;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1.5em;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: 1em;
  top: 1em;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.active-button {
  background: var(--primary-color) !important;
  cursor: pointer !important;
  transition: transform 0.2s ease;

  &:hover {
    background-color: var(--primary-hover-color) !important;
    color: var(--text-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}
</style>
