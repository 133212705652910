<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { capitalFirstLetter } from "@/helpers/capitalLetter";
import { ref, onMounted } from "vue";

const store = useStore();

const payload = ref({
  name: "",
  direct: false,
});

function close(e: Event, close?: boolean) {
  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "department-modal") store.commit("titles/CLOSE_MODAL");
}

onMounted(() => {
  document.addEventListener("keyup", (e) => {
    if (e.key === "Escape") store.commit("titles/CLOSE_MODAL");
  });
});

function createDep() {
  doRequest("/personal/create/department", {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify(payload.value),
  })
    .then(({ response }) => {
      payload.value.name = "";
      store.commit("titles/CLOSE_MODAL");
      store.commit("titles/SET_DEPARTMENT_ID", response);
    })
    .catch((error) => {
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
}
</script>

<template>
  <div class="department-modal" @keyup.esc="close" @mousedown="close">
    <div class="departments-create">
      <div class="departments-wrp" tabindex="0">
        <h3>Создать структурное подразделение</h3>
        <div>
          <label>Наименование структурного подразделения</label>
          <input type="text" v-model="payload.name" @input="capitalFirstLetter" />
        </div>
        <div class="direct">
          <label for="_direct">
            <input type="checkbox" id="_direct" v-model="payload.direct" />
            Непосредственное подчинение руководителю
          </label>
        </div>
        <button :class="{ 'active-button': payload.name.length > 3 }" :disabled="payload.name.length < 4" @click="createDep">Создать</button>
      </div>
      <button type="button" class="close" @click="close($event, true)"></button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.department-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--darkening-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 7;

  & .departments-create {
    border-radius: 0.4em;
    padding: 2em 5em;
    background-color: var(--secondary-background-color);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2em;

    & .departments-wrp {
      display: flex;
      flex-direction: column;
      gap: 2em;

      & div {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }

      & input {
        outline: none;
        border: none;
        border: 0.1em solid var(--fourth-border-color);
        padding: 0.4em 0.4em;
        border-radius: 0.4em;
        color: var(--secondary-text-color);
      }

      & select {
        cursor: pointer;
        outline: none;
        border: none;
        border: 0.1em solid var(--fourth-border-color);
        padding: 0.4em 0.4em;
        border-radius: 0.4em;
        color: var(--secondary-text-color);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-size: 1em;
        background-position: calc(100% - 1em) center;
        background-repeat: no-repeat;
      }

      .selected {
        background-image: url("@/share/assets/icons/arrowStickUpIcon.svg") !important;
      }

      .unselected {
        background-image: url("@/share/assets/icons/arrowStickDownIcon.svg") !important;
      }

      & button {
        display: block;
        padding: 1em 0;
        width: 100%;
        border: none;
        outline: none;
        background-color: var(--button-deactivate-color);
        color: var(--text-color);
        border-radius: 0.4em;
        cursor: not-allowed;

        &:hover {
          color: var(--text-hover-color);
        }
      }
    }
  }
}

.close {
  display: block;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1.5em;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: 1em;
  top: 1em;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.direct {
  & label {
    display: flex;
    gap: 1em;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  & input {
    display: block;
  }
}

.active-button {
  background: var(--primary-color) !important;
  cursor: pointer !important;
  transition: transform 0.2s ease;

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}
</style>
