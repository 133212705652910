<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { FileUploader } from "@/features";
import { useRoute, useRouter } from "vue-router";

interface iPayload {
  priorityId: number | null;
  executorId: number | null;
  taskInfo: {
    title: string;
    description: string;
    deadLine: string | null;
    files: { filename: string; mimetype: string; size: number; url: string; uid: string }[];
  };
  tree?: {
    parentTaskId: number;
    id: number | null;
  };
}
const store = useStore();
const route = useRoute();
const router = useRouter();

const down = ref(false);
const x = ref(window.innerWidth / 5);
const y = ref(window.innerHeight / 5);

const personal = ref<{ name: string; position: string | null; value: number | null | false }[]>([]);
const loaderState = ref(false);

const payload = ref<iPayload>({
  priorityId: parseInt(localStorage.getItem("payload/createRequest/priorityId")!) || 2,
  executorId: null,
  taskInfo: {
    title: "",
    description: "",
    deadLine: null,
    files: [],
  },
});

const dateCheked = ref(false);
const timeCheked = ref(false);

const fileUploadedModal = ref(false);
const today = ref(getDate());
const expandSelect = ref<number | null>(null);

function expander(num: number) {
  if (expandSelect.value === num) return (expandSelect.value = null);
  return (expandSelect.value = num);
}

function getDate() {
  const regex = new RegExp(/(\d{4}-\d{2}-\d{2})/);
  const mutch = new Date().toISOString().match(regex);
  if (mutch?.length) {
    return mutch[0];
  } else return "";
}

function setPlanedDate(e: Event) {
  e.preventDefault();
  const button = e.target as HTMLButtonElement;
  if (button.classList.length > 1) {
    button.classList.remove("create-request__set-time-cheked");
    payload.value.taskInfo.deadLine = null;
    dateCheked.value = false;
    timeCheked.value = false;
  } else {
    button.classList.add("create-request__set-time-cheked");
    dateCheked.value = true;
  }
  return;
}

function setPlanedTime(e: Event) {
  e.preventDefault();
  const button = e.target as HTMLButtonElement;
  if (button.classList.length > 1) {
    button.classList.remove("create-request__set-time-cheked");
    payload.value.taskInfo.deadLine = `${payload.value.taskInfo.deadLine?.split("T")[0]}T23:59:59`;
    timeCheked.value = false;
  } else {
    button.classList.add("create-request__set-time-cheked");
    timeCheked.value = true;
  }
  return;
}

const priorities = [
  {
    name: "Высокий",
    value: 1,
  },
  {
    name: "Обычный",
    value: 2,
  },
];

function createRequest(e: Event) {
  if (payload.value.executorId === null) {
    store.commit("events/PUSH_EVENT", {
      id: undefined,
      message: "Вы не выбрали исполнителя",
      type: "error",
    });
    return;
  }
  if (payload.value.taskInfo.title === null) {
    store.commit("events/PUSH_EVENT", {
      id: undefined,
      message: "Вы не заполнили тему задачи",
      type: "error",
    });
    return;
  }
  if (payload.value.priorityId === null) {
    store.commit("events/PUSH_EVENT", {
      id: undefined,
      message: "Вы не заполнили приоритет задачи",
      type: "error",
    });
    return;
  }

  loaderState.value = true;

  store.commit("titles/CLOSE_MODAL");
  store.commit("componentLoader/TOGGLE_STATE");
  if (route.name === "TaskInfo") {
    payload.value.tree = {
      id: store.state.tasks.task?.treeId || null,
      parentTaskId: parseInt(route.params.id as string),
    };
  }
  doRequest("/tasks/create", {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify(payload.value),
  })
    .then(({ response }) => {
      loaderState.value = false;
      payload.value.taskInfo.title = "";
      payload.value.taskInfo.description = "";
      payload.value.priorityId = 0;
      localStorage.removeItem("createTask");
      store.commit("componentLoader/TOGGLE_STATE");
      if (store.state.app.mobile) {
        router.back();
      }
    })
    .catch((error) => {
      loaderState.value = false;
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
}

function showUploadedModal() {
  fileUploadedModal.value = true;
}

onMounted(() => {
  if (store.state.app.mobile) {
    store.commit("titles/SET_TITLE", "Новая задача");
    store.commit("app/CHANGE_STATE_RETURN", true);
  }
  doRequest("/personal/list", { method: "GET" })
    .then(({ response }) => {
      personal.value = response;
    })
    .catch((error) => {});
});
onBeforeUnmount(() => {
  store.commit("app/CHANGE_STATE_RETURN", false);
});

function close(e: Event, close?: boolean) {
  localStorage.setItem("createTask", JSON.stringify(payload.value));
  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "create-request") store.commit("titles/CLOSE_MODAL");
}

function closeX(e: Event, close?: boolean) {
  payload.value.taskInfo.title = "";
  payload.value.taskInfo.description = "";
  payload.value.priorityId = 0;
  localStorage.removeItem("createTask");

  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "create-request") store.commit("titles/CLOSE_MODAL");
}

function addFiles(msg: { message: string; files: { filename: string; mimetype: string; size: number; url: string; uid: string }[] }) {
  payload.value.taskInfo.files = msg.files;
  fileUploadedModal.value = true;
}

function setTime(e: Event) {
  const { value } = e.target as HTMLInputElement;
  payload.value.taskInfo.deadLine = `${payload.value.taskInfo.deadLine?.split("T")[0]}T${value}:00`;
}

function setDate(e: Event) {
  const { value } = e.target as HTMLInputElement;
  if (payload.value.taskInfo.deadLine && /23:59:59/.test(payload.value.taskInfo.deadLine)) {
    payload.value.taskInfo.deadLine = `${value}T${payload.value.taskInfo.deadLine?.split("T")[1]}`;
    return;
  }
  payload.value.taskInfo.deadLine = `${value}T23:59:59`;
}

function mousedown(e: MouseEvent) {
  const { target } = e as MouseEvent & { target: HTMLElement };
  let count = 20;
  function getParentNode(elem: HTMLElement | ParentNode) {
    count--;
    if (count < 0) return false;
    if (elem instanceof HTMLElement && elem.className === "form") return true;
    if (elem.parentNode) return getParentNode(elem.parentNode);
    return false;
  }
  const parentForm = getParentNode(target);
  if (target && target.className && target.className === "form") down.value = true;
  else {
    if (!parentForm) close(e, true);
  }
}
function mousemove(e: MouseEvent) {
  if (down.value) {
    x.value += e.movementX;
    y.value += e.movementY;
  }
}
function mouseup(e: MouseEvent) {
  down.value = false;
}

function removeFile(uid: string) {
  payload.value.taskInfo.files = payload.value.taskInfo.files.filter((f) => f.uid !== uid);
}

onMounted(() => {
  const exist = localStorage.getItem("createTask");
  if (exist) {
    const data = JSON.parse(exist);
    if (data.executorId) payload.value.executorId = data.executorId;
    if (data.taskInfo) payload.value.taskInfo = data.taskInfo;
    if (data.priorityId) payload.value.priorityId = data.priorityId;
  }
  document.addEventListener("keyup", (e) => {
    if (e.key === "Escape") store.commit("titles/CLOSE_MODAL");
  });
});
</script>

<template>
  <div class="create-request" @mousedown="mousedown" @mouseup="mouseup" @mousemove="mousemove" @mouseleave="down = false" @keyup.esc="$emit('close', 'createTask')">
    <form @submit.prevent="createRequest" class="form" :style="{ top: `${y}px`, left: `${x}px`, position: 'absolute' }">
      <p class="form__title extra" v-if="!store.state.app.mobile">Создать задачу</p>
      <div class="form__column">
        <div class="create-request__wrp">
          <div class="form__content create-request__theme">
            <p class="form__title required">Тема:</p>
            <textInput
              :textVal="payload.taskInfo.title"
              @inputVal="(value: string) => (payload.taskInfo.title = value.charAt(0).toUpperCase() + value.slice(1))"
              :readonly="false"
              required
            ></textInput>
          </div>
          <div class="form__content create-request__select" :class="{ expand: expandSelect === null || expandSelect !== 1, collapse: expandSelect === 1 }">
            <div>
              <p class="form__title required">Приоритет:</p>
              <select required v-model="payload.priorityId" @click="expander(1)">
                <option v-for="prio in priorities" :key="prio.name" :value="prio.value">
                  {{ prio.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form__content create-request__description">
            <p class="form__title">Описание:</p>
            <textAreaComp :textVal="payload.taskInfo.description" @inputVal="(value: string) => (payload.taskInfo.description = value.charAt(0).toUpperCase() + value.slice(1))">
            </textAreaComp>
            <p class="form__title" v-if="payload.taskInfo.files.length && !store.state.app.mobile">Загружено {{ payload.taskInfo.files.length }} файлов(а)</p>
            <FileUploader :destination="'task'" @add-file="addFiles" :showAddText="true" @remove="removeFile" :uploaded-files="payload.taskInfo.files" />
          </div>
        </div>
        <div class="create-request__orgs-wrp">
          <div style="display: flex; flex-direction: column; gap: 1em">
            <div class="form__content create-request__select" :class="{ expand: expandSelect === null || expandSelect !== 2, collapse: expandSelect === 2 }">
              <div>
                <p class="form__title">Исполнитель:</p>
                <select required v-model="payload.executorId" @click="expander(2)">
                  <option :value="null" selected disabled>Выберите исполнителя</option>
                  <option
                    v-for="(pers, index) in personal"
                    :key="index"
                    :value="pers.value"
                    :disabled="!pers.value"
                    :style="{
                      textAlign: pers.value ? 'left' : 'center',
                      fontWeight: pers.value === false ? 'bold' : 'normal',
                    }"
                  >
                    {{ `${pers.name} ${pers.position ? pers.position : ""}` }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__content create-request__datetime">
              <label for="_date-button" class="form__date-button">Запланировать дату исполнения</label>
              <button class="create-request__set-time" @click="setPlanedDate" id="_date-button"></button>
            </div>
            <div class="form__content create-request__datetime" v-if="dateCheked">
              <input type="date" id="_date" @change="setDate" required :min="today" />
            </div>
            <div class="form__content create-request__datetime" v-else>
              <input type="datetime-local" id="_date" style="opacity: 0" />
            </div>
            <div v-if="dateCheked" class="form__content create-request__datetime">
              <label for="_datetime-button" class="form__date-button">Запланировать время</label>
              <button class="create-request__set-time" @click="setPlanedTime" id="_datetime-button"></button>
            </div>
            <label for="_datetime-button" class="form__date-button" v-if="!dateCheked" style="opacity: 0"
              >Запланировать время
              <!-- <button class="create-request__set-time" @click="setPlanedTime" id="_datetime-button"></button> -->
            </label>
            <div class="form__content create-request__datetime" v-if="timeCheked">
              <input type="time" id="_time" @change="setTime" />
            </div>
            <div class="form__content create-request__datetime" v-else>
              <input type="time" id="_time" style="opacity: 0" />
            </div>
            <div class="form__content">
              <div v-if="store.state.app.mobile" class="button-wrp">
                <FileUploader :destination="'task'" @add-file="addFiles" @remove="removeFile" :showAddText="true" :uploaded-files="payload.taskInfo.files" />
                <label class="button-label" v-if="payload.taskInfo.files.length" @click="showUploadedModal">Посмотреть файлы</label>
              </div>
              <p class="form__title" v-if="payload.taskInfo.files.length && store.state.app.mobile">Загружено {{ payload.taskInfo.files.length }} файлов(а)</p>
            </div>
          </div>
          <div class="form__submit-button">
            <button v-if="!loaderState" type="submit" class="form__buttons">Создать</button>
            <button v-else type="submit" class="form__buttons__loader">Создать<span></span><span></span><span></span></button>
          </div>
          <button type="button" class="close" v-if="!store.state.app.mobile" @click="closeX($event, true)"></button>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 480px) {
  .form__column {
    flex-direction: column;
    gap: 1em;
  }

  .create-request {
    width: 100%;

    &__orgs-wrp {
      gap: 1em;
      width: calc(100vw - 2em);
    }
  }

  .form {
    padding: 1em;
    width: calc(100% - 2em);
  }

  .form__title {
    font-size: 1em;
  }

  .form__submit-button {
    justify-content: center;
  }
}

@media screen and (min-width: 480px) {
  .form__title {
    font-size: 1.1em;
  }

  .form__column {
    justify-content: space-between;
  }

  .create-request {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: var(--darkening-background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    z-index: 3;
    overflow: hidden;

    &__orgs-wrp {
      flex-basis: 48%;
      gap: 0.5em;
    }

    & form {
      width: 60%;
      height: 50%;
      padding: 2em;
      display: flex;
      flex-direction: column;
      gap: 1em;
      background-color: var(--secondary-background-color);
    }
  }

  .form__submit-button {
    justify-content: end;
  }
}

.button-label {
  display: block;
  width: max-content;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0.6em 1em;
  background-color: var(--primary-color);
  border-radius: 0.4em;
  color: var(--text-color);
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}

.button-wrp {
  display: flex;
  gap: 0.5em;
}

.form {
  position: absolute;
  border-radius: 0.4em;
}

.close {
  display: block;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1.5em;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: 1em;
  top: 1em;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.select-orgs {
  margin: 0;
  padding: 0.4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 20%;
  flex-wrap: wrap;
  text-align: center;
  aspect-ratio: 5/2;
  height: max-content;
  border: 0.1em solid var(--fourth-border-color);
  border-radius: 0.4em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: wrap;
  word-break: break-word;
  cursor: pointer;
}

.form__column {
  display: flex;
  height: 100%;

  & input {
    justify-self: auto;
    padding: 0.5em;
    outline: none;
    border: 0.1em solid var(--fourth-border-color);
    border-radius: 0.4em;
    word-wrap: break-word !important;
  }

  & input[type="file"] {
    display: none;
  }
}

.form__title {
  margin: 0;
  text-align: left;
  color: var(--secondary-border-color);
}

.form__date-button {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.form__content {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.form__submit-button {
  display: flex;
}

.form__buttons {
  display: block;
  outline: none;
  border: none !important;
  background-color: transparent;
  padding: 0.6em 1em;
  background-color: var(--primary-color);
  border-radius: 0.4em;
  color: var(--text-color);
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}
.form__buttons__loader {
  @extend .form__buttons;
  position: relative;
  color: var(--primary-color);
  & span {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 0.6em;
    aspect-ratio: 1/1;
    color: var(--text-color);
    background-color: #fff;
    border-radius: 50%;
  }
  & span:nth-child(1) {
    left: calc(50% - 15%);
    animation: load 1.5s linear 0s infinite;
  }
  & span:nth-child(2) {
    left: 50%;
    animation: load 1.5s linear 0.3s infinite;
  }
  & span:nth-child(3) {
    left: calc(50% + 15%);
    animation: load 1.5s linear 0.6s infinite;
  }
}

.extra {
  font-size: 1.5em;
}

.create-request {
  &__orgs-wrp {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  &__set-time {
    position: relative;
    outline: none;
    border: none;
    display: block;
    width: 2.892em;
    aspect-ratio: 16/7;
    background-color: var(--fourth-background-color);
    border-radius: 2em;
    gap: 0.4em;

    &::after {
      content: "";
      width: 1.3em;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: var(--third-background-color);
      position: absolute;
      left: 0;
      transform: translate(0, -50%);
      transition: all 0.1s;
    }
  }

  &__set-time-cheked {
    background-color: var(--button-background-button);

    &::after {
      left: calc(100% - 1.3em);
      right: 0 !important;
      background-color: var(--primary-color);
    }
  }

  &__wrp {
    display: flex;
    flex-direction: column;
    gap: 1em;
    flex-basis: 48%;
  }

  &__select {
    width: 100%;
    display: flex;
    position: relative;

    & div {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      box-sizing: border-box;
      gap: 1em;

      & select {
        border: none;
        outline: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0.424em;
        border: 0.1em solid var(--fourth-border-color);
        border-radius: 0.4em;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        & option {
          width: 100%;
          margin: 0;
          display: flex;
        }
      }
    }
  }

  &__datetime {
    display: flex;
    flex-direction: row;
    align-items: center;

    & input[type="date"],
    & input[type="datetime-local"] {
      border: none;
      outline: none;
      padding: 0.4em 0.5em;
      border: 0.1em solid var(--fourth-border-color);
      border-radius: 0.4em;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & textarea {
      border: none;
      display: block;
      outline: none;
      flex-grow: 1;
      resize: none;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      padding: 0.4em 0.5em;
      border: 0.1em solid var(--fourth-border-color);
      border-radius: 0.4em;
    }
  }

  &__theme {
    display: flex;
    flex-direction: column;

    & input {
      flex-grow: 1;
    }
  }
}

.selected-org {
  color: var(--text-color);
  border: 0.1em solid var(--primary-color) !important;
  background-color: var(--primary-color);
}

.expand {
  &::after {
    position: absolute;
    right: 0.6em;
    top: 2.8em;
    content: "";
    width: 1em;
    aspect-ratio: 1/1;
    background-image: url("@/share/assets/icons/selectArrow.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.collapse {
  &::after {
    position: absolute;
    right: 0.6em;
    top: 2.8em;
    content: "";
    width: 1em;
    aspect-ratio: 1/1;
    background-image: url("@/share/assets/icons/selectArrow.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
  }
}
</style>
